import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {Link} from "react-router-dom";

export default function WebinarTestimonials() {
    const testimonials = [
        {
            quote: "Webinary Mikołaja są niesamowicie interesujące! Przekazałam córce całą wiedzę i po 3 tygodniach zaczęła przynosić same piątki, mimo tego, że wcześniej była trójkową uczennicą.",
            name: "Anna",
            role: "Mama Alicji z II Liceum",
            imageUrl: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/testimonials/anna.png"
        },
        {
            quote: "Dzień dobry, chciałem Panu podziękować za ostatni webinar. Zdałem sobie sprawę, jak wiele błędów popełnialiśmy z żoną. Maciek zaczął działać tak jak najlepsi uczniowie i szkoła nie sprawia mu żadnych problemów. Dziękuję Panu.",
            name: "Adam",
            role: "Tata Maćka z 8 klasy",
            imageUrl: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/testimonials/adam.png"
        },
        {
            quote: "Po ostatnim Pana webinarze w końcu zrozumiałam, dlaczego syn tak niechętnie podchodził do nauki. Po wspólnym obejrzeniu Pana webinaru nareszcie zrozumiał, że musi zmienić swoje działanie. Teraz uczy się systematycznie każdego dnia i wierzy w to, że uda mu się dostać na wymarzoną architekturę w Londynie!",
            name: "Magdalena",
            role: "Mama Michała z klasy maturalnej",
            imageUrl: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/testimonials/magdalena.png"
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [lastInteraction, setLastInteraction] = useState(Date.now());

    // Automatically switch testimonials every 5 seconds, reset on interaction
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 8000);
        return () => clearInterval(interval);
    }, [testimonials.length, lastInteraction]);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
        setLastInteraction(Date.now());
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex + 1) % testimonials.length
        );
        setLastInteraction(Date.now());
    };

    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
        setLastInteraction(Date.now());
    };

    const {quote, name, role, imageUrl} = testimonials[currentIndex];

    const transitionVariants = {
        initial: {opacity: 0, x: 20},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: -20}
    };

    return <>
        <h2 className='mt-12'>Co mówią inni?</h2>
        <div
            className="text-center position-relative"
            style={{padding: '50px 0', overflow: 'visible'}}
        >
            <div
                className="d-flex justify-content-center align-items-center mb-16"
                style={{width: '100%', overflow: 'visible'}}
            >
                {/* Previous Button */}
                <button
                    onClick={handlePrevious}
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '3rem',
                        color: '#fff',
                        marginRight: '1rem',
                        zIndex: 2,
                        position: 'relative'
                    }}
                    aria-label="Previous Testimonial"
                >
                    &#8249;
                </button>

                {/* Testimonial Animation Wrapper */}
                <div
                    className="position-relative mx-4"
                    style={{overflow: 'visible'}}
                >
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentIndex}
                            variants={transitionVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{duration: 0.5}}
                            style={{position: 'relative', overflow: 'visible'}}
                        >
                            <p
                                className="position-relative mb-0 fw-medium fs-10 fw-semibold px-md-16 py-12 py-md-0"
                                style={{
                                    zIndex: 2,
                                    letterSpacing: '-1px',
                                    overflow: 'visible',
                                }}
                            >
                                {quote}
                                <img
                                    className="position-absolute top-0 start-0 mt-n6 ms-n7"
                                    src="https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/quote-top-green.svg"
                                    alt=""
                                    style={{zIndex: 1, pointerEvents: 'none', width: '80px'}}
                                />
                                <img
                                    className="position-absolute bottom-0 end-0 mb-n6 me-n7"
                                    src="https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/quote-down-green.svg"
                                    alt=""
                                    style={{zIndex: 1, pointerEvents: 'none', width: '80px'}}
                                />
                            </p>
                        </motion.div>
                    </AnimatePresence>
                </div>

                {/* Next Button */}
                <button
                    onClick={handleNext}
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '3rem',
                        color: '#fff',
                        marginLeft: '1rem',
                        zIndex: 2,
                        position: 'relative'
                    }}
                    aria-label="Next Testimonial"
                >
                    &#8250;
                </button>
            </div>

            {/* Image if present */}
            {imageUrl && (
                <div style={{zIndex: 2, position: 'relative', margin: '40px auto'}}>
                    <img
                        src={imageUrl}
                        alt={name}
                        style={{maxWidth: '100px', borderRadius: '50%'}}
                    />
                </div>
            )}

            <h3 className="mb-2 fs-8 fw-semibold text-light-dark text-white"
                style={{zIndex: 2, position: 'relative'}}>{name}</h3>
            <p className="mb-8 fs-10 text-secondary-light fw-medium"
               style={{zIndex: 2, position: 'relative'}}>{role}</p>

            {/* Indicator Dots */}
            <div className="d-inline-flex" style={{zIndex: 2, position: 'relative'}}>
                {testimonials.map((_, i) => (
                    <Link
                        key={i}
                        to=""
                        onClick={(e) => {
                            e.preventDefault();
                            handleDotClick(i);
                        }}
                        className={i === currentIndex ? "mx-1 bg-success rounded-pill" : "mx-1 bg-light rounded-pill"}
                        style={{width: 12, height: 12, display: 'inline-block'}}
                    />
                ))}
            </div>
        </div>
    </>

}