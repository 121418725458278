import Meta from "../../../../components/common/Meta";
import React from "react";
import Heading from "../../../../components/common/header/Heading";
import AnimatedButton from "../../../../components/ui/motion/AnimatedButton";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import MainDesign from "../../../MainDesign";

export default function WebinarSlides() {
    const handleDownload = () => {
        TraceService.addTrace(TraceType.BtnClick, 'Pobierz slajdy');
        const fileUrl = "https://produkacja.s3.eu-central-1.amazonaws.com/webinar/Jak+pomo%CC%81c+swojemu+dziecku+zostac%CC%81+najlepszym+uczniem.pdf";
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = "Jak pomóc swojemu dziecku zostać najlepszym uczniem.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Meta title="Slajdy"/>
            <MainDesign containerStyles='container py-28' minHeight='100vh'>
                <Heading title="Zyskaj dostęp do slajdów"
                         subtitle='Aby uzyskać dostęp do slajdów, wystarczy kliknąć w poniższy przycisk.'/>
                <div className="row justify-content-center g-4 pb-8 pt-4">
                    <div className="col-12 col-md-auto">
                        <AnimatedButton
                            className="btn btn-lg btn-success w-100 text-success-light shadow"
                            onClick={handleDownload}
                        >
                            Pobierz slajdy
                        </AnimatedButton>
                    </div>
                </div>
                <p className='text-center fw-medium fs-10 text-warning'>Cała prezentacja waży około 50MB, więc
                    pobieranie
                    jej może
                    chwilę zająć.</p>
            </MainDesign>

        </>
    );
}