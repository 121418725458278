import React, {Suspense, useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {AnimatePresence, motion} from 'framer-motion';
import LoadingPage from "../../../pages/common/LoadingPage";

const LazyEditor = React.lazy(() => {
    // @ts-ignore
    import('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
    return import('react-draft-wysiwyg').then(module => ({default: module.Editor}));
});

interface WyswigEditorProps {
    rawEditorState?: string;
    saveEditorContent: (newState: string) => void;
}

export default function WyswigEditor(props: WyswigEditorProps) {
    const {rawEditorState, saveEditorContent} = props;

    const [editorState, setEditorState] = useState<any | null>(null);
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [previousContent, setPreviousContent] = useState<string | null>(
        rawEditorState || null
    );

    // Load initial state
    useEffect(() => {
        const loadEditorState = async () => {
            const {EditorState, convertFromRaw} = await import('draft-js');
            setEditorState(
                rawEditorState
                    ? EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorState)))
                    : EditorState.createEmpty()
            );
        };
        loadEditorState();
    }, [rawEditorState]);

    // Save logic (only if content has actually changed)
    const saveContent = async (state: any, convertToRaw: any) => {
        if (!state) return;
        const content = JSON.stringify(convertToRaw(state.getCurrentContent()));

        // Compare with previous content to avoid unnecessary saves
        if (content !== previousContent) {
            saveEditorContent(content);
            setPreviousContent(content);

            // Show a "saved" message briefly
            setShowSavedMessage(true);
            setTimeout(() => {
                setShowSavedMessage(false);
            }, 2000);
        }
    };

    // Debounced save (3s after user stops typing)
    const saveContentDebounced = useDebouncedCallback(
        async (state: any, convertToRaw: any) => {
            saveContent(state, convertToRaw);
        },
        3000
    );

    // Editor state change
    const onEditorStateChange = async (newState: any) => {
        const {convertToRaw} = await import('draft-js');
        setEditorState(newState);
        saveContentDebounced(newState, convertToRaw);
    };

    // Blur handler => immediate save in case user leaves quickly
    const onBlurHandler = async () => {
        const {convertToRaw} = await import('draft-js');
        saveContent(editorState, convertToRaw);
    };

    return (
        <div>
            <div
                onBlur={onBlurHandler}
                style={{position: 'relative'}}
            >
                <Suspense fallback={<LoadingPage/>}>
                    {editorState && (
                        <LazyEditor
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            editorStyle={{zIndex: 10000}}
                            // Some typical classes
                            toolbarClassName="text-dark bg-light-dark"
                            wrapperClassName="text-white border rounded"
                            editorClassName="text-white px-3 pb-6"
                            toolbar={{
                                options: ['inline', 'list', 'colorPicker'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                    bold: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/bold.png',
                                        className: 'bg-light-dark border-light-dark hover-border-secondary-dark',
                                    },
                                    italic: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/italicize.png',
                                        className: 'bg-light-dark border-light-dark hover-border-secondary-dark',
                                    },
                                    underline: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/underline.png',
                                        className: 'bg-light-dark border-light-dark hover-border-secondary-dark',
                                    },
                                },
                                list: {
                                    options: ['unordered', 'ordered'],
                                    unordered: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/bullet-points.png',
                                        className: 'bg-light-dark border-light-dark hover-border-secondary-dark',
                                    },
                                    ordered: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/ordered-list.png',
                                        className: 'bg-light-dark border-light-dark hover-border-secondary-dark',
                                    },
                                },
                                colorPicker: {
                                    icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/color-picker.png',
                                    className:
                                        'bg-light-dark border-light-dark hover-border-secondary-dark',
                                    popupClassName: 'bg-light-dark text-white',
                                    colors: ['#ffffff', '#2ad137', '#f59e0c', '#8896ab', '#ef5844', '#3944bc'],
                                },
                            }}
                        />
                    )}
                </Suspense>

                {/* AnimatePresence to fade in/out the 'Saved' message. */}
                <AnimatePresence>
                    {showSavedMessage && (
                        <motion.div
                            initial={{opacity: 0, y: 10}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 10}}
                            transition={{duration: 0.4}}
                            className="mb-3"
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: '0.8rem',
                                color: 'limegreen',
                                fontSize: '1rem',
                                background: 'transparent',
                                pointerEvents: 'none',
                            }}
                        >
                            Pomyślnie zapisano.
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}