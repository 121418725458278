import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import ProductPageOption from "../../../components/user/ai/pricing/ProductPageOption";
import {motion} from "framer-motion";
import {offerVariants} from "./OfferLandingPage";

export default function ParentOrStudentSelectPage() {
    return <>
        <Meta title='MISTRZOWSKIE Wsparcie'
              description='Jeżeli jesteś rodzicem, wybierz rodzica. Jeżeli jesteś uczniem, wybierz ucznia.'/>
        <MainDesign containerStyles='container py-md-36 py-20' minHeight='100vh'>
            <Heading customSubtitleStyles='fs-8 fw-medium text-secondary-light' subtitle='Wybierz właściwą opcję'
                     title='MISTRZOWSKIE Wsparcie'/>
            <div className='row mw-5xl mx-auto'>
                <motion.div
                    custom={1}
                    variants={offerVariants}
                    initial="hidden"
                    animate="visible"
                    className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
                >
                    <ProductPageOption
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/rodzic2.png'
                        redirectTo='/mistrzowskie-wsparcie/spotkanie' title='Jestem świadomym rodzicem'
                        ctaCustomText='Przechodzę dalej'
                    />
                </motion.div>
                <motion.div
                    custom={1}
                    variants={offerVariants}
                    initial="hidden"
                    animate="visible"
                    className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
                >
                    <ProductPageOption
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/uczen.png'
                        ctaCustomText='Przechodzę dalej'
                        redirectTo='/mistrzowskie-wsparcie/uczen' title='Jestem ambitnym uczniem'
                    />
                </motion.div>
            </div>
        </MainDesign>
    </>
}