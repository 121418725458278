import LoadingPage from "../../common/LoadingPage";
import MainDesign from "../../MainDesign";
import {useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {PurchaseOption} from "../../../model/user/purchase/PurchaseOption";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

export default function ConfirmTransactionPage() {
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl');
    const purchaseOptionName = searchParams.get('purchaseOptionName');
    const option = PurchaseOption[purchaseOptionName as keyof typeof PurchaseOption];

    useEffect(() => {
        if (option && option.price) {
            console.log('transaction successful')
        }

        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${redirectUrl}, purchaseOptionName: ${purchaseOptionName}`);
        window.location.href = redirectUrl ?? '/';
    }, [option, purchaseOptionName, redirectUrl]);

    return <>
        <MainDesign containerStyles='py-28 container'>
            <LoadingPage/>
        </MainDesign>
    </>
}

