import React from "react";

export interface SimpleTestimonialProps {
    imageUrl: string
    quote: string
    name: string
    description: string
}

export default function SimpleTestimonial({imageUrl, quote, name, description}: SimpleTestimonialProps) {
    return <>
        <div
            className="text-center position-relative"
            style={{padding: '50px 0', overflow: 'visible'}}
        >
            <div
                className="d-flex justify-content-center align-items-center mb-16"
                style={{width: '100%', overflow: 'visible'}}
            >

                {/* Testimonial Animation Wrapper */}
                <div
                    className="position-relative mx-4"
                    style={{overflow: 'visible'}}
                >
                    <div
                        style={{position: 'relative', overflow: 'visible'}}
                    >
                        <p
                            className="position-relative mb-0 fw-medium fs-6 fw-semibold px-md-16 py-12 py-md-0"
                            style={{
                                zIndex: 2,
                                letterSpacing: '-1px',
                                overflow: 'visible',
                            }}
                        >
                            {quote}
                            <img
                                className="position-absolute top-0 start-0 mt-n6 ms-n7"
                                src="https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/quote-top-green.svg"
                                alt=""
                                style={{zIndex: 1, pointerEvents: 'none', width: '80px'}}
                            />
                            <img
                                className="position-absolute bottom-0 end-0 mb-n6 me-n7"
                                src="https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/quote-down-green.svg"
                                alt=""
                                style={{zIndex: 1, pointerEvents: 'none', width: '80px'}}
                            />
                        </p>
                    </div>
                </div>

            </div>

            {/* Image if present */}
            {imageUrl && (
                <div style={{zIndex: 2, position: 'relative', margin: '40px auto'}}>
                    <img
                        src={imageUrl}
                        alt={name}
                        style={{maxWidth: '100px', borderRadius: '50%'}}
                    />
                </div>
            )}

            <h3 className="mb-2 fs-8 fw-semibold text-light-dark text-white"
                style={{zIndex: 2, position: 'relative'}}>{name}</h3>
            <p className="mb-8 fs-10 text-secondary-light fw-medium"
               style={{zIndex: 2, position: 'relative'}}>{description}</p>
        </div>
    </>
}