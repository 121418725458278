import React, {useEffect, useRef, useState} from 'react';
import {WebinarDetails} from "../../model/user/webinar/WebinarDetails";

// Declare Wistia’s global
declare global {
    interface Window {
        _wq: any[];
    }
}

interface CallAfterTime {
    time: number; // in seconds
    action: (wistiaPlayer: any) => void;
}

interface WebinarPlayerProps {
    /** The Wistia video ID to load */
    videoId: string;

    /** For the "live simulation" effect—simulating that the webinar started X seconds ago. */
    liveSimulation?: boolean;

    /**
     * If you want to jump the video to a certain timestamp,
     * e.g. how many seconds have passed since webinar's official start.
     */
    start?: number;

    /**
     * This replicates your "callAfterTime" logic from Vimeo.
     * Once currentTime >= `time`, we call `action(player)` (only once).
     */
    callAfterTime?: CallAfterTime;

    /** If needed for any context (ID, user, etc.). */
    webinarDetails: WebinarDetails;

    /**
     * Callback for watch time. We’ll call this once per *integer* second,
     * so you don’t get spammed with half-second calls.
     */
    onWatchTimeUpdate?: (secondsWatched: number) => void;

    /** Show or hide Wistia’s progress bar (playbar) */
    showProgressBar?: boolean;

    /** Auto-play on load */
    autoPlay?: boolean;

    /** Force the user to not pause (like your original code) */
    forceContinuousPlay?: boolean;

    /**
     * NEW: A function to call exactly once, the first time
     * this component is mounted. It will never be called again
     * unless the user fully reloads the page (which resets the JS).
     */
    onFirstMountOnce?: () => void;
}

/**
 * Tracks if we have already called `onFirstMountOnce` in this browsing session
 * (i.e. since the last full page reload).
 */
let hasCalledOnFirstMountOnce = false;

/**
 * A Wistia-based Webinar Player that mimics the old Vimeo-based “liveSimulation” logic.
 * - Loads the Wistia script on mount (if needed).
 * - Binds to `secondchange` but throttles calls to once per integer second.
 * - Optionally jumps to `start` second (once) if `liveSimulation` is true.
 * - Optionally forces continuous play by resuming if the user pauses.
 * - Wrapped in a responsive 16:9 container.
 * - Optionally calls `onFirstMountOnce` exactly once per full page load.
 */
export default function WebinarPlayer({
                                          videoId,
                                          webinarDetails,
                                          liveSimulation = false,
                                          start = 0,
                                          callAfterTime,
                                          onWatchTimeUpdate,
                                          showProgressBar = true,
                                          autoPlay = false,
                                          forceContinuousPlay = true,
                                          onFirstMountOnce,
                                      }: WebinarPlayerProps) {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    // Keep reference to Wistia player
    const wistiaPlayerRef = useRef<any>(null);

    // Ensure we only call `callAfterTime.action` once
    const [hasCalledAction, setHasCalledAction] = useState(false);

    // Guard: only set the initial `start` time once
    const hasSetStartTimeRef = useRef(false);

    // Keep track of the last second we’ve processed (avoid multiple calls per second)
    const lastFullSecondRef = useRef<number>(-1);

    // 1. Call onFirstMountOnce exactly once (if provided).
    useEffect(() => {
        if (onFirstMountOnce && !hasCalledOnFirstMountOnce) {
            hasCalledOnFirstMountOnce = true;
            onFirstMountOnce();
        }
    }, [onFirstMountOnce]);

    // 2. Load the Wistia script (if not already on the page)
    useEffect(() => {
        const scriptId = 'wistia-script';
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
            script.async = true;
            script.onload = () => setScriptLoaded(true);
            document.body.appendChild(script);
        } else {
            setScriptLoaded(true);
        }
    }, []);

    // 3. Configure the Wistia player once the script is loaded
    useEffect(() => {
        if (!scriptLoaded) return;

        // If Wistia hasn't loaded yet, push to the global _wq array
        window._wq = window._wq || [];

        window._wq.push({
            id: videoId,
            options: {
                // Show/hide the progress bar
                playbar: showProgressBar,
                // Auto-play if desired
                autoPlay,
                // Hide speed control, keep volume & settings
                playbackRateControl: false,
                volumeControl: true,
                settingsControl: true,
                copyLinkAndThumbnailEnabled: false,
                // Hide the standard play/pause button
                playpause: false,
                // Keep fullscreen
                fullscreenButton: true,
                // Remove the small overlay play button
                smallPlayButton: false,
            },
            onReady: (video: any) => {
                // Save reference
                wistiaPlayerRef.current = video;

                // Force continuous play (if desired)
                if (forceContinuousPlay) {
                    video.bind('pause', () => {
                        video.play();
                    });
                }

                // Jump to `start` seconds (only once)
                if (!hasSetStartTimeRef.current && liveSimulation && start > 0) {
                    hasSetStartTimeRef.current = true;
                    video.time(start);
                }

                // Bind to `secondchange` but throttle calls to once per integer second
                video.bind('secondchange', (currentTime: number) => {
                    const flooredSecond = Math.floor(currentTime);
                    if (flooredSecond !== lastFullSecondRef.current) {
                        lastFullSecondRef.current = flooredSecond;

                        // onWatchTimeUpdate?
                        onWatchTimeUpdate?.(flooredSecond);

                        // Check callAfterTime (only once)
                        if (
                            callAfterTime &&
                            !hasCalledAction &&
                            flooredSecond >= callAfterTime.time
                        ) {
                            callAfterTime.action(video);
                            setHasCalledAction(true);
                        }
                    }
                });
            },
        });
    }, [
        scriptLoaded,
        videoId,
        showProgressBar,
        autoPlay,
        forceContinuousPlay,
        liveSimulation,
        start,
        callAfterTime,
        onWatchTimeUpdate,
        hasCalledAction,
    ]);

    // --- RESPONSIVE EMBED WRAPPER ---
    return (
        <div style={{position: 'relative', paddingBottom: '56.25%', height: 0}}>
            <div
                className={`wistia_embed wistia_async_${videoId}`}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            />
        </div>
    );
}