import React, {Suspense} from 'react';
import Meta from "../../components/common/Meta";
import {Await, defer, Params, useLoaderData} from "react-router-dom";
import {DeferredData} from "@remix-run/router/dist/utils";
import LoadingPage from "../common/LoadingPage";
import MainDesign from "../MainDesign";
import ErrorContent from "../../components/common/error/ErrorContent";
import {PurchaseService} from "../../service/PurchaseService";
import {KedActivationCode} from "../../model/user/purchase/KedAccessAfterWebinar";
import {ApiError} from "../../service/HttpService";
import GrantKedAccessToStudentText from "../../components/auth/GrantKedAccessToStudentText";
import GrantKedAccessToStudentForm from "../../components/auth/GrantKedAccessToStudentForm";

type LoaderData = {
    userData: Promise<KedActivationCode>;
};

export default function GrantKedAccessToStudentPage() {
    const {userData} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Aktywacja konta'/>
        <MainDesign containerStyles="py-md-28 py-16 container">
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={userData} errorElement={<ErrorContent/>}>
                    {(_: KedActivationCode) => <>
                        <GrantKedAccessToStudentText/>
                        <GrantKedAccessToStudentForm/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadKedActivationCode(uuid: string): Promise<KedActivationCode> {
    return await PurchaseService.getKedActivationCode(uuid)
}

export function loader({params}: { params: Params<"uuid"> }): DeferredData {
    return defer({
        userData: loadKedActivationCode(params.uuid!!)
    });
}

export async function action({request, params}: { request: Request, params: Params<"uuid"> }) {
    const formData = await request.formData();

    const code = params.uuid!!
    const activateAccountRequest = {
        email: formData.get('email') as string,
        name: formData.get('name') as string,
        code: code
    };

    try {
        const {message} = await PurchaseService.grantKedAccessAfterWebinar(activateAccountRequest);
        return {
            status: 201,
            body: message
        }
    } catch (error: any) {
        const apiError = error as ApiError
        return {
            status: apiError.httpsStatus,
            body: apiError.message
        }
    }
}
