import React, {Suspense, useEffect, useState} from "react";
import {Await, defer, useLoaderData, useSearchParams, useSubmit} from "react-router-dom";
import DOMPurify from 'dompurify';
import {ApiError} from "../../../service/HttpService";
import ConfirmModal from "../../../components/ui/ConfirmModal";
import MainDesign from "../../MainDesign";
import Meta from "../../../components/common/Meta";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {
    AdminSendNewsletterRequest,
    AdminToggleNewsletterRequest,
    NewsletterEntity,
    SubscribeNewsletterRequest
} from "../../../model/user/general/Newsletter";
import AnimatedButton from "../../../components/ui/motion/AnimatedButton";
import {useHandleDirtyForm} from "../../../hooks/useHandleDirtyForm";
import {NewsletterService} from "../../../service/NewsletterService";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import CustomSelect from "../../../components/ui/form/CustomSelect";
import RichContentEditor from "../../../components/common/editor/RichContentEditor";
import RadioTwoOptions from "../../../components/ui/form/RadioTwoOptions";
import MailingListTable from "../../../components/admin/newsletter/MailingListTable";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";

export enum SendNewsletterType {
    TestEmails = "Testowy email (I)",
    DailyMathExercises = "Subskrybenci codziennych zadań z matematyki",
    Newsletter = "Subskrybenci newslettera",
    Planner = "Subskrybenci planera",
    KedOptInParent = "Rodzice OptIn KED (I)",
    KedOptInStudent = "Uczniowie OptIn KED (I)",
    KedOptInMaturaParent = "Rodzice maturzystów (I) - Auto FE",
    KedOptInAll = "OptIn KED - wszyscy (I) - Auto FE",
    MWOptInParent = "Rodzice uczniów do Mistrzowskiego Wsparcia (I) - Auto FE",
    KedScheduledSalesCall = "Umówieni na sales call KED (wszyscy) (I)",
    KedMaturaParentScheduledSalesCall = "Umówieni na sales call KED (rodzice maturzystów) (I)",
    MWParentScheduledSalesCall = "Umówieni na sales call MW (rodzice) (I)",
    WebinarKedOptInV1 = 'OptIn Webinar (wszyscy) (I)',
    WebinarKedNoShowV1 = 'NoShow Webinar (I) - Auto FE',
    WebinarKedFollowUpV1 = 'FollowUp Webinar (I) - Auto FE',
    WebinarKedPurchaserV1 = 'Klient KED (płatnik) (I - może być inne...)',
    RegisteredUsersNewsletterContent = "Zarejestrowani użytkownicy (zgoda na marketing) (I)",
    RegisteredUsersAll = "Zarejestrowani użytkownicy (wszyscy) (I)",
}

const LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY = 'newsletterSubject'
const LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY = 'newsletterMessage'

type LoaderData = {
    mailingList: Promise<NewsletterEntity[]>
}


export default function AdminNewsletter() {
    const {mailingList} = useLoaderData() as LoaderData;

    const [subject, setSubject] = useState(localStorage.getItem(LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY) ?? '');
    const [email, setEmail] = useState('');
    const [joinedBefore, setJoinedBefore] = useState('');
    const [source, setSource] = useState('');
    const [preview, setPreview] = useState('');
    const [message, setMessage] = useState(localStorage.getItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY) ?? '');
    const initialNewsletterTypesState = Object.keys(SendNewsletterType).reduce((acc, key) => {
        acc[key as keyof typeof SendNewsletterType] = false;
        return acc;
    }, {} as Record<keyof typeof SendNewsletterType, boolean>);

    const [selectedNewsletterTypes, setSelectedNewsletterTypes] = useState(initialNewsletterTypesState);
    const [selectedNewsletterTypesToExclude, setSelectedNewsletterTypesToExclude] = useState(initialNewsletterTypesState);
    const [openSendToAllModal, setOpenSendToAllModal] = useState(false);

    // ====================== Add to newsletter ======================
    const [addToNewsletterName, setAddToNewsletterName] = useState('');
    const [addToNewsletterEmail, setAddToNewsletterEmail] = useState('');
    const [addToNewsletterSource, setAddToNewsletterSource] = useState('');
    const [addToNewsletterType, setAddToNewsletterType] = useState<NewsletterType>(NewsletterType.Newsletter);

    const submit = useSubmit();
    const [_, setIsFormDirty] = useHandleDirtyForm();

    const [isRichContentEditorEnabled, setIsRichContentEditorEnabled] = React.useState(true);

    const [isMailingListToExcludeVisible, setIsMailingListToExcludeVisible] = React.useState(false);

    const [searchParams] = useSearchParams();

    const {isSubmitting} = useSubmitFormAction(`/admin/newsletter${searchParams.get('mailingList') ? '?mailingList=' + searchParams.get('mailingList') : ''}`, null, () => {
        setSelectedNewsletterTypes(initialNewsletterTypesState);
        setSelectedNewsletterTypesToExclude(initialNewsletterTypesState);
        setOpenSendToAllModal(false);
        if (addToNewsletterEmail !== '') {
            setAddToNewsletterName('');
            setAddToNewsletterEmail('');
        } else {
            localStorage.removeItem(LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY);
            localStorage.removeItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY);
        }
    });

    useEffect(() => {
    }, [message]);

    function getSelectedTypesArray() {
        return Object.entries(selectedNewsletterTypes)
            .filter(([_, isSelected]) => isSelected)
            .map(([type]) => type);
    }

    function getSelectedTypesToExcludeArray() {
        return Object.entries(selectedNewsletterTypesToExclude)
            .filter(([_, isSelected]) => isSelected)
            .map(([type]) => type);
    }

    async function sendForm(action: "SEND_NEWSLETTER" | "ADD_TO_NEWSLETTER") {
        if (action === "SEND_NEWSLETTER") {
            submit({
                action: "SEND_NEWSLETTER",
                subject: subject,
                message: message,
                email: email,
                source: source,
                preview: preview,
                joinedBefore: joinedBefore,
                types: getSelectedTypesArray(),
                typesToExclude: getSelectedTypesToExcludeArray()
            }, {
                method: "POST"
            });
        } else if (action === "ADD_TO_NEWSLETTER") {
            submit({
                action: "ADD_TO_NEWSLETTER",
                name: addToNewsletterName,
                source: addToNewsletterSource,
                email: addToNewsletterEmail,
                type: addToNewsletterType
            }, {
                method: "POST"
            });
        }
    }

    const createMarkup = () => {
        return {__html: DOMPurify.sanitize(message)};
    };

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isAddToNewsletterEmailValid = !!addToNewsletterEmail.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    function isFormValid() {
        return message.trim().length >= 10 && subject.trim().length >= 5 && (getSelectedTypesArray().length > 0 || isEmailValid);
    }

    const options = Object.keys(SendNewsletterType); // Assuming NewsletterType prop is an object

    function toggleCheckboxToAddNewsletter(option: keyof typeof SendNewsletterType) {
        setSelectedNewsletterTypes(prevState => ({
            ...prevState,
            [option]: !prevState[option]
        }));
    }

    function toggleCheckboxToExcludeMailingList(option: keyof typeof SendNewsletterType) {
        setSelectedNewsletterTypesToExclude(prevState => ({
            ...prevState,
            [option]: !prevState[option]
        }));
    }

    return <>
        <Meta title="Newsletter"/>
        <MainDesign containerStyles="container py-28" minHeight="92vh">
            <div className='row'>
                <h2> Dodaj manualnie do newslettera </h2>
                <div className={`col-12 col-md-2 form-group pt-3`}>
                    <input
                        className='form-control p-4 shadow bg-dark'
                        id='admin-newsletter-name-1'
                        type='text'
                        aria-describedby='emailHelp'
                        placeholder='Wprowadź imię'
                        name='name'
                        value={addToNewsletterName}
                        onChange={(event) => setAddToNewsletterName(event.target.value)}
                        style={addToNewsletterName ? {color: 'white'} : {}}
                    />
                </div>
                <div className={`col-12 col-md-3 form-group pt-3`}>
                    <input
                        className='form-control p-4 shadow bg-dark'
                        id='admin-newsletter-name-1'
                        type='text'
                        aria-describedby='emailHelp'
                        placeholder='Wprowadź maila'
                        name='name'
                        value={addToNewsletterEmail}
                        onChange={(event) => setAddToNewsletterEmail(event.target.value)}
                        style={addToNewsletterEmail ? {color: 'white'} : {}}
                    />
                </div>
                <div className={`col-12 col-md-2 form-group pt-3`}>
                    <input
                        className='form-control p-4 shadow bg-dark'
                        id='admin-newsletter-name-1'
                        type='text'
                        aria-describedby='emailHelp'
                        placeholder='Wprowadź źródło'
                        name='name'
                        value={addToNewsletterSource}
                        onChange={(event) => setAddToNewsletterSource(event.target.value)}
                        style={addToNewsletterSource ? {color: 'white'} : {}}
                    />
                </div>
                <div className={`col-12 col-md-2 form-group pt-3`}>
                    <CustomSelect name='newsletterType' value={addToNewsletterType}
                                  onChange={(event) => setAddToNewsletterType(event.target.value as NewsletterType)}
                                  customStyles={'select-md p-4'}>
                        {
                            Object.keys(NewsletterType).map(type => (
                                <option value={type} key={type}>{type}</option>
                            ))
                        }
                    </CustomSelect>
                </div>
                <div className={`col-12 col-md-3 form-group pt-2`}>
                    <AnimatedButton className='btn btn-lg btn-success w-100 text-success-light shadow'
                                    disabled={isSubmitting || !isAddToNewsletterEmailValid}
                                    onClick={() => sendForm("ADD_TO_NEWSLETTER")}>Potwierdź</AnimatedButton>
                </div>
            </div>
            <hr className='text-white mt-8'/>
            <div className='row g-16 mt-4'>
                <div className='col-12 col-md-6'>
                    <div className='mw-xl-2xl'>
                            <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                              Newsletter
                            </span>
                        <h1
                            className='mb-6 font-heading fs-5 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Napisz wiadomość
                        </h1>
                        <p className='mb-8 fs-9 fw-medium text-secondary-light'>
                            Skorzystaj z formularza poniżej, aby napisać nową wiadomość email.
                        </p>
                        <div className="mb-3">
                            <label
                                className='mb-2 text-white fw-bold'
                                htmlFor='admin-newsletter-type'
                            >
                                Lista mailingowa
                            </label>
                            {options.map((option, index) => {
                                return (
                                    <div className="form-check text-white" key={index}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`checkbox-${option}`}
                                            checked={selectedNewsletterTypes[option as keyof typeof SendNewsletterType]}
                                            onChange={() => toggleCheckboxToAddNewsletter(option as keyof typeof SendNewsletterType)}
                                        />
                                        <label style={{userSelect: "none"}} className="form-check-label"
                                               htmlFor={`checkbox-${option}`}>
                                            {SendNewsletterType[option as keyof typeof SendNewsletterType]}
                                        </label>
                                    </div>
                                );
                            })}
                            <AnimatedButton
                                className={`btn btn-sm btn-outline-success mt-3 ${isMailingListToExcludeVisible ? 'mb-4' : 'mb-0'}`}
                                onClick={() => setIsMailingListToExcludeVisible(!isMailingListToExcludeVisible)}>{isMailingListToExcludeVisible ? "Ukryj wykluczenie" : "Wyklucz listy mailingowe"}</AnimatedButton>
                            {
                                isMailingListToExcludeVisible &&
                                <p className='text-white fw-bold mb-2'>Wyklucz poniższe listy mailingowe</p>
                            }
                            {
                                isMailingListToExcludeVisible && options.map((option, index) => {
                                    return (
                                        <div className="form-check text-white" key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${option}-exclude`}
                                                checked={selectedNewsletterTypesToExclude[option as keyof typeof SendNewsletterType]}
                                                onChange={() => toggleCheckboxToExcludeMailingList(option as keyof typeof SendNewsletterType)}
                                            />
                                            <label style={{userSelect: "none"}} className="form-check-label"
                                                   htmlFor={`checkbox-${option}-exclude`}>
                                                {SendNewsletterType[option as keyof typeof SendNewsletterType]}
                                            </label>
                                        </div>
                                    );
                                })}

                            <p className='mb-2 mt-3 fs-11 fw-medium text-secondary-light'>
                                {"Wysyłając maila, na dole należy dodać link do anulowania subskrypcji z wartością {{Cancel_Newsletter_Url}}. Zostanie on dynamicznie podmieniony."}
                            </p>
                            <p className='mb-2 mt-3 fs-11 fw-medium text-secondary-light'>
                                {"Wysyłając wiadomość, w której są 'Zarejestrowani użytkownicy (wszyscy)', nie dodajemy tego!!"}
                            </p>

                            <label
                                className='mb-1 mt-2 fw-medium text-white'
                                htmlFor='signInInput3-2'
                            >
                                Temat
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow mb-3'
                                id='signInInput3-2'
                                type='text'
                                autoComplete="off"
                                placeholder='Wprowadź temat'
                                name='subject'
                                value={subject}
                                onChange={event => {
                                    setSubject(event.target.value)
                                    localStorage.setItem(LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY, event.target.value);
                                }}
                            />
                            <label
                                className='mb-1 mt-2 fw-medium text-white'
                                htmlFor='signInInput3-3'
                            >
                                Adres odbiorcy (zamiast listy mailingowej)
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow mb-3'
                                id='signInInput3-3'
                                type='text'
                                autoComplete="off"
                                placeholder='Wprowadź adres email (opcjonalnie)'
                                name='email'
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                            <label
                                className='mb-1 mt-2 fw-medium text-white'
                                htmlFor='signInInput3-3'
                            >
                                Dołączono przed (data) (opcjonalnie ISO String, np. 2024-10-20)
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow mb-3'
                                id='signInInput3-3'
                                type='text'
                                autoComplete="off"
                                placeholder='Wprowadź datę w formacie ISO String'
                                name='email'
                                value={joinedBefore}
                                onChange={event => setJoinedBefore(event.target.value)}
                            />
                            <label
                                className='mb-0 mt-2 fw-medium text-white'
                                htmlFor='signInInput3-4'
                            >
                                Nadawca maila. Należy
                                podawać {"<nadawca@produkacja.pl>"} na końcu!
                            </label>
                            <label
                                className='mb-1 mt-1 fw-medium text-white'
                                htmlFor='signInInput3-4'
                            >
                                Przykład: "{"Marysia z Produkacji <marysia@produkacja.pl>"}"
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow mb-3'
                                id='signInInput3-4'
                                type='text'
                                autoComplete="off"
                                placeholder='Marysia z Produkacji <marysia@produkacja.pl> (opcjonalne)'
                                name='source'
                                value={source}
                                onChange={event => setSource(event.target.value)}
                            />
                            <label
                                className='mb-1 mt-1 fw-medium text-white'
                                htmlFor='signInInput3-14'
                            >
                                Podgląd zawartości maila, np. catchy zdanie (opcjonalne)
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow mb-3'
                                id='signInInput3-14'
                                type='text'
                                autoComplete="off"
                                placeholder='90% osób robi to źle 🤯'
                                name='preview'
                                value={preview}
                                onChange={event => setPreview(event.target.value)}
                            />
                            <label
                                className='mb-1 fw-medium text-white'
                                htmlFor='email-input'
                            >
                                Zawartość maila
                            </label>
                            <RadioTwoOptions handleRadioChange={(event) => {
                                setIsRichContentEditorEnabled(event.target.value === "ckeditor")
                            }} checked={isRichContentEditorEnabled}
                                             label="Wybierz edytor" firstText="CKEditor" firstValue="ckeditor"
                                             secondText="Zwykły edytor" secondValue="Raw"/>
                            {
                                isRichContentEditorEnabled &&
                                <RichContentEditor content={message} setContent={setMessage}
                                                   onChange={(data: string) => {
                                                       setIsFormDirty(true);
                                                       localStorage.setItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY, data);
                                                   }}/>
                            }
                            {
                                !isRichContentEditorEnabled &&
                                <textarea className="form-control mb-4 text-secondary-dark" name="field-name"
                                          rows={5}
                                          placeholder="Zacznij pisać..." value={message} id="email-input"
                                          onChange={event => {
                                              setIsFormDirty(true);
                                              setMessage(event.target.value);
                                              localStorage.setItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY, event.target.value);
                                          }}></textarea>
                            }
                        </div>
                        <div className='row g-4'>
                            <div className='col-12 col-md-auto'>
                                <AnimatedButton
                                    className='btn btn-lg btn-success w-100 text-success-light shadow'
                                    onClick={() => setOpenSendToAllModal(true)}
                                    disabled={!isFormValid() || isSubmitting}
                                >
                                    Wyślij
                                </AnimatedButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mw-md-2xl'>
                            <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                              Podgląd
                            </span>
                        <h1
                            className='mb-6 font-heading fs-5 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Zobacz podgląd
                        </h1>
                        <p className='mb-8 fs-9 fw-medium text-secondary-light'>
                            Zobacz w czasie rzeczywistym, jak będzie wyglądać Twoja wiadomość.
                        </p>
                        <div className='mb-6 fs-8 font-heading text-white pt-md-64 mt-md-40'>
                            [{subject}]
                        </div>
                        <div className='mb-6 fs-10 text-white'>
                            <div dangerouslySetInnerHTML={createMarkup()} className='html-live-preview'/>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={mailingList} errorElement={<ErrorContent/>}>
                    {(loadedMailingList) => <>
                        <MailingListTable mailingList={loadedMailingList}/>
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
        <ConfirmModal isOpen={openSendToAllModal} setOpen={setOpenSendToAllModal}
                      submitDisabled={isSubmitting}
                      children={<p className='mb-0 mt-4 text-danger fs-10 fw-medium'>Czy na pewno chcesz
                          kontynuować? <span className='text-decoration-underline fw-bold'>
                          Tej akcji nie można cofnąć.</span></p>}
                      title={`Zaraz wyślesz ${isEmailValid ? "bezpośredniego maila" : " maila do wszystkich osób z podanej listy mailingowej."}`}
                      subtitleWarn={`Wysyłasz maila do: ${isEmailValid ? email : getSelectedTypesArray().map(type => SendNewsletterType[type as keyof typeof SendNewsletterType]).join(", ") + (getSelectedTypesToExcludeArray().length > 0 ? (" z wykluczeniem " + getSelectedTypesToExcludeArray().map(type => SendNewsletterType[type as keyof typeof SendNewsletterType]).join(", ")) : "")}`}
                      confirmButtonAction={() => sendForm("SEND_NEWSLETTER")}/>
    </>
}

async function loadMailingList(mailingList: string): Promise<NewsletterEntity[]> {
    return await NewsletterService.getMailingList(mailingList as NewsletterType);
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const mailingList = url.searchParams.get('mailingList') ?? NewsletterType.WebinarKedOptInV1;

    return defer({
        mailingList: loadMailingList(mailingList)
    })
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const action = formData.get('action') as string;

    let response;

    try {
        if (action === 'SEND_NEWSLETTER') {
            response = await sendNewsletter(formData);
        } else if (action === 'ADD_TO_NEWSLETTER') {
            response = await addToNewsletter(formData);
        } else if (action === 'TOGGLE_NEWSLETTER') {
            response = await toggleNewsletter(formData);
        } else {
            throw new Error("unknown state")
        }

        return {
            status: 200,
            body: response.message
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}

async function sendNewsletter(formData: FormData) {
    const rawTypes = formData.get('types') as string;
    const rawTypesToExclude = formData.get('typesToExclude') as string;
    const types = rawTypes.split(",") as SendNewsletterType[];
    const typesToExclude = rawTypesToExclude.split(",") as SendNewsletterType[];
    const sendNewsletterData = {
        subject: formData.get('subject') as string,
        message: formData.get('message') as string,
        email: formData.get('email') as string,
        joinedBefore: formData.get('joinedBefore') as string,
        source: formData.get('source') as string,
        preview: formData.get('preview') as string,
        types: types,
        typesToExclude: typesToExclude.length === 1 && typesToExclude[0] as any === "" ? [] : typesToExclude
    } as AdminSendNewsletterRequest;

    return await NewsletterService.sendNewsletter(sendNewsletterData);
}

async function addToNewsletter(formData: FormData) {
    const subscribeNewsletterRequest = {
        email: formData.get('email') as string,
        source: formData.get('source') as string,
        name: formData.get('name') as string,
        type: formData.get('type') as string,
        manual: true
    } as SubscribeNewsletterRequest;

    return await NewsletterService.subscribeNewsletter(subscribeNewsletterRequest);
}

async function toggleNewsletter(formData: FormData) {
    const toggleNewsletterRequest = {
        email: formData.get('email') as string,
        type: formData.get('type') as string,
    } as AdminToggleNewsletterRequest;

    return await NewsletterService.toggleNewsletter(toggleNewsletterRequest);
}