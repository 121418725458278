import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Heading from "../../../../common/header/Heading";
import AnimatedButton from "../../../../ui/motion/AnimatedButton";
import DependentOnScreenSizeElement from "../../../../ui/element/DependentOnScreenSizeElement";

// Reusable helper to format seconds into Hh Mm Ss or Mm Ss or Ss
function formatTimeLeft(seconds: number): string {
    if (seconds <= 0) return "0s";
    const s = Math.floor(seconds % 60);
    const m = Math.floor((seconds / 60) % 60);
    const h = Math.floor(seconds / 3600);
    if (h > 0) return `${h}h ${m}m ${s}s`;
    if (m > 0) return `${m}m ${s}s`;
    return `${s}s`;
}

/** Returns number of seconds until next midnight (24:00) Warsaw time. */
function getSecondsUntilMidnightInWarsaw(): number {
    // Get current time in Warsaw
    const nowInWarsaw = new Date(
        new Date().toLocaleString("en-US", {timeZone: "Europe/Warsaw"})
    );

    // Create a copy and set it to 'today at 24:00' (which effectively becomes tomorrow at 00:00).
    const nextMidnight = new Date(nowInWarsaw);
    nextMidnight.setHours(24, 0, 0, 0);

    // Calculate difference in seconds
    const diff = (nextMidnight.getTime() - nowInWarsaw.getTime()) / 1000;
    return Math.max(0, Math.floor(diff));
}

interface SalesPageCTAProps {
    submit: () => void;
    isSubmitting: boolean;
    fromWebinar: boolean;
    minimal?: boolean;
    email?: string;
    setEmail?: React.Dispatch<React.SetStateAction<string>>;
    name?: string;
    setName?: React.Dispatch<React.SetStateAction<string>>;
}

export default function SalesPageCTA({
                                         submit,
                                         isSubmitting,
                                         fromWebinar,
                                         minimal,
                                         email,
                                         setEmail,
                                         name,
                                         setName
                                     }: SalesPageCTAProps) {
    // Determine initial difference
    const [timeLeft, setTimeLeft] = useState<number>(getSecondsUntilMidnightInWarsaw());

    const fullPrice = fromWebinar ? 997 : 2997;
    const splitPrice = fromWebinar ? 333 : 999;
    const discountPercentage = fromWebinar ? 83 : 50;


    const isEmailValid = !!email?.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isInputForWebsiteKedEnrollValid = isEmailValid && name && name.trim().length >= 2;

    useEffect(() => {
        // Update every second
        const intervalId = setInterval(() => {
            setTimeLeft(getSecondsUntilMidnightInWarsaw());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return <>
        {
            !minimal && <>
                <Heading
                    title={`🔥 ${discountPercentage}% rabatu na dostęp do Kursu! 🔥`}
                    badgeText="Pozostał ostatni krok"
                    customTitleStyles="font-heading mb-4 fs-5 text-white"
                />
                <p className="text-white fw-medium fs-5">
                <span className="text-decoration-line-through text-danger fw-bold me-2">
                  5985 zł
                </span>
                    <span className="text-success fw-bold">{fullPrice} zł</span>
                </p>
                <p className="text-white fw-medium">
                    Jednorazowa płatność{" "}
                    <span className="fw-bold text-success">{fullPrice} zł</span> lub{" "}
                    <span className="fw-bold text-success">3 raty 0% ({splitPrice} zł/mc)</span>.
                </p>
            </>
        }


        {
            minimal ? <DependentOnScreenSizeElement mobileElement={<p className="text-white fw-medium fs-6 mt-2">
                    Promocja kończy się za:&nbsp;<span className='d-md-none'><br/></span>
                    <span className="fw-bold text-warning">{formatTimeLeft(timeLeft)}</span>
                </p>} desktopElement={<p className="text-white fw-medium fs-7 mt-2">
                    Promocja kończy się za:&nbsp;<span className='d-md-none'><br/></span>
                    <span className="fw-bold text-warning">{formatTimeLeft(timeLeft)}</span>
                </p>}/> :
                <p className="text-white fw-medium fs-6 mt-2">
                    Promocja kończy się za:&nbsp;<span className='d-md-none'><br/></span>
                    <span className="fw-bold text-warning">{formatTimeLeft(timeLeft)}</span>
                </p>
        }

        {
            fromWebinar ? <>
                <div className="mw-md-xl mx-auto border-light rounded shadow p-1 text-start">
                    <div className="mb-1 row g-4">
                        <div className="col-12">
                            <AnimatedButton
                                className="btn btn-lg btn-success fs-11 pt-3 w-100 text-success-light shadow"
                                disabled={isSubmitting}
                                type="button"
                                onClick={submit}
                                customScale={1.05}
                            >
                                {isSubmitting ? "Proszę czekać..." : "Kup teraz"}
                            </AnimatedButton>
                        </div>
                    </div>
                    <p className="mb-1 fs-13 fw-medium text-white text-left mt-0">
                        <span>Przechodząc dalej akceptujesz </span>
                        <Link target="_blank" className="text-success link-success" to="/polityka-prywatnosci">
                            politykę prywatności
                        </Link>
                        <span>, </span>
                        <Link target="_blank" className="text-success link-success" to="/regulamin">
                            regulamin
                        </Link>
                        <span> oraz </span>
                        <Link target="_blank" className="text-success link-success" to="/regulamin-ked">
                            regulamin KED
                        </Link>
                        <span>. Wszystkie instrukcje dotyczące dostępu do kursu zostaną wysłane na adres email podany przy płatości.</span>
                    </p>
                </div>
            </> : <>
                <div className='mw-md-xl mx-auto border-light rounded shadow p-1 text-start'>
                    <p
                        className={`fw-medium fs-10`}
                    >
                        Poniżej podaj dane osoby,{" "}
                        <span className='fw-bold'>dla której</span> kupujesz dostęp do kursu.
                    </p>
                    <div className='mb-4 row g-6'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label
                                    className='mb-1 fw-medium text-white'
                                    htmlFor='purchase-course-name-input'
                                >
                                    Imię
                                </label>
                                <input
                                    className='form-control text-secondary-dark shadow'
                                    id='purchase-course-name-input'
                                    type='text'
                                    placeholder='Podaj imię'
                                    name='name'
                                    autoComplete='name'
                                    value={name}
                                    onChange={(event) => setName!!(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label
                                    className='mb-1 fw-medium text-white'
                                    htmlFor='purchase-course-email-input'
                                >
                                    Email
                                </label>
                                <input
                                    className='form-control text-secondary-dark shadow'
                                    id='purchase-course-email-input'
                                    type='email'
                                    placeholder='Podaj adres email'
                                    name='email'
                                    autoComplete='email'
                                    value={email}
                                    onChange={(event) => setEmail!!(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
                        <span>Przechodząc dalej akceptujesz </span>
                        <Link target="_blank" className='text-success link-success' to='/polityka-prywatnosci'>
                            politykę prywatności
                        </Link>
                        <span>, </span>
                        <Link target="_blank" className='text-success link-success' to='/regulamin'>
                            regulamin
                        </Link>
                        <span> oraz </span>
                        <Link target="_blank" className='text-success link-success' to='/regulamin-ked'>
                            regulamin KED
                        </Link>
                        <span>. </span>
                    </p>
                    <div className='mb-3 row g-4'>
                        <div className='col-12'>
                            <AnimatedButton
                                className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                                disabled={isSubmitting || !isInputForWebsiteKedEnrollValid}
                                type="button"
                                onClick={submit}
                                customScale={1.05}
                            >
                                {isSubmitting ? 'Proszę czekać...' : 'Kontynuuj'}
                            </AnimatedButton>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}