import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import ProductsSelectOption from "../../../components/user/products/ProductsSelectOption";
import {DeferredData} from "@remix-run/router/dist/utils";
import {defer, useLoaderData} from "react-router-dom";
import {isEffectiveActionCourseSubscriber, redirectIfNotLoggedIn} from "../../../util/AuthUtil";

interface LoaderData {
    isEffectiveActionCourseSubscriber: boolean
}

export default function ProductsPage() {
    const {isEffectiveActionCourseSubscriber} = useLoaderData() as LoaderData;
    return <>
        <Meta title='Produkty'/>
        <MainDesign containerStyles='container py-12'>
            <ProductsSelectOption isEffectiveActionCourseSubscriber={isEffectiveActionCourseSubscriber}/>
        </MainDesign>
    </>
}

export async function loader(): Promise<DeferredData | Response> {
    const redirect = await redirectIfNotLoggedIn();
    if (redirect) {
        return redirect;
    }

    return defer({
        isEffectiveActionCourseSubscriber: await isEffectiveActionCourseSubscriber(),
    });
}
