import Vimeo from "@u-wave/react-vimeo";
import React, {useRef, useState} from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {useSearchParams} from "react-router-dom";
import {KED_WEBINAR_EXPERIMENT_1} from "../../../util/ExperimentUtil";

export interface VideoWithPlayButtonProps {
    onPlay?: (playerRef: any) => void;
    vimeoVideoId: string;
    id: string;
    customDivWrapperClasses?: string;
    trackStatistics?: boolean;
    callAfterTime?: CallAfterTime;
    hidePlayButton?: boolean;
    autoPlay?: boolean;
    start?: number;
    /**
     * If true, the video behaves like a "live stream":
     * - Autoplays and auto-resumes if paused
     * - Hides speed controls, title, byline, and portrait
     * - Shows default controls for volume & quality (user sees a timeline, but can't effectively pause – we auto-resume)
     * - Attempts to start with sound; if browser blocks it, shows "Włącz dźwięk" overlay
     */
    liveSimulation?: boolean;
}

interface CallAfterTime {
    time: number;
    action: (playerRef: any) => void;
}

export default function VideoWithPlayButton(props: VideoWithPlayButtonProps) {
    const {
        onPlay,
        vimeoVideoId,
        id,
        customDivWrapperClasses,
        trackStatistics,
        callAfterTime,
        hidePlayButton,
        autoPlay,
        start,
        liveSimulation,
    } = props;

    const [playButtonVisible, setPlayButtonVisible] = useState(!hidePlayButton);
    const [overlayVisible, setOverlayVisible] = useState(!hidePlayButton);
    const [paused, setPaused] = useState(false);
    const [ended, setEnded] = useState(false);
    const [lastWatchedTime, setLastWatchedTime] = useState<number | null>(null);

    // For "Włącz dźwięk" overlay
    const [muteOverlayVisible, setMuteOverlayVisible] = useState(false);

    const playerRef = useRef<any>(null);
    const intervalRef = useRef<number | null>(null);

    const [searchParams] = useSearchParams();

    const variation = searchParams.get('variation');
    const source = searchParams.get('source');

    /**
     * Try to set volume=1. If autoplay with sound is blocked,
     * show an overlay so the user can click "Włącz dźwięk" manually.
     */
    async function attemptEnableSound(player: any) {
        try {
            // Some browsers will allow this if the user previously interacted with the page,
            // otherwise it may throw an error or simply remain muted.
            await player.setVolume(1);
            setMuteOverlayVisible(false);
        } catch (err) {
            console.log(err)
            // Autoplay with sound might be blocked
            setMuteOverlayVisible(true);
        }
    }

    function handlePlayerReady(player: any) {
        player.on("pause", handlePlayerPause);
        player.on("play", handlePlayerPlay);
        player.on("ended", handlePlayerEnd);
        console.log('player-ready')

        // In "liveSimulation" mode, or if autoPlay is true, try to enable sound from the start.
        if (liveSimulation || autoPlay) {
            setMuteOverlayVisible(true);
        }

        // Cleanup function
        return () => {
            player.off("pause", handlePlayerPause);
            player.off("play", handlePlayerPlay);
            player.off("ended", handlePlayerEnd);
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }

    function handlePlayerEnd() {
        TraceService.addTrace(TraceType.PlayVideo, "Video has ended.");
        setEnded(true);
    }

    function handlePlayerPlay() {
        if (ended) return;
        setPaused(false);

        // Clear any old intervals
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = window.setInterval(async () => {
            if (playerRef.current?.player) {
                const time = await playerRef.current.player.getCurrentTime();
                const hadEnded = await playerRef.current.player.getEnded();

                if (ended) return;

                // Only send trace if current time changed
                if (lastWatchedTime !== time) {
                    TraceService.addTrace(
                        TraceType.PlayVideo,
                        `Watched time: ${time}, ended: ${hadEnded}, paused: ${paused}`
                    );
                    setLastWatchedTime(time);
                }

                if (callAfterTime) {
                    if (time >= callAfterTime.time) {
                        try {
                            callAfterTime.action(playerRef);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }

                if (hadEnded && intervalRef.current !== null) {
                    setEnded(true);
                    clearInterval(intervalRef.current);
                }
            }
        }, 5000);
    }

    async function handlePlayerPause() {
        if (liveSimulation) {
            // Immediately resume if user tries to pause a "live" video
            const p = playerRef.current?.player;
            if (p) {
                await p.play().catch(() => null);
            }
        } else {
            // Normal pause behavior
            setPaused(true);
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
            TraceService.addTrace(TraceType.PlayVideo, "Stopped video.");
        }
    }

    const handlePlayClick = () => {
        setPlayButtonVisible(false);
        playVideo();
    };

    const playVideo = () => {
        setOverlayVisible(false);
        setPlayButtonVisible(false);

        // Command the iframe to "play" via postMessage
        const iframe = document.querySelector(`#${id} .vimeo-iframe iframe`);
        if (iframe) {
            // @ts-ignore
            iframe.contentWindow.postMessage({method: "play"}, "*");
        }

        if (onPlay) {
            onPlay(playerRef);
        }
    };

    // If user clicks “Włącz dźwięk” overlay
    const handleUnmuteClick = async () => {
        const p = playerRef.current?.player;
        if (p) {
            await attemptEnableSound(p);
        }
        if (liveSimulation) {
            TraceService.addTraceWithExperiment(TraceType.PlayVideo, KED_WEBINAR_EXPERIMENT_1, variation, source);
        }
    };

    /**
     * The @u-wave/react-vimeo props that hide speed, hide title/byline/portrait,
     * show normal controls (volume + quality), and attempt autoplay if liveSimulation or autoPlay is set.
     */
    const showControls = true; // we need volume & quality
    const vimeoProps = {
        video: `https://vimeo.com/${vimeoVideoId}?share=copy&autoplay=1`,
        // Hide speed controls:
        speed: false,
        // Hide these metadata elements:
        showByline: !liveSimulation, // or set false if you always want to hide them
        showTitle: !liveSimulation,
        showPortrait: !liveSimulation,
        // Show the main controls bar (volume, etc.):
        controls: showControls,
        // Let the video be responsive
        responsive: true,
        // If tracking is off, set dnt to true
        dnt: !trackStatistics,
        // Autoplay if liveSimulation or autoPlay
        autoplay: liveSimulation || autoPlay,
        // Potentially start from a given second offset
        start,
        // The "paused" state (only relevant if not in liveSimulation)
        paused,
        // Try not to autopause if you have multiple videos
        autopause: false,
        // Attempt to start with sound
        muted: liveSimulation,
        // This event fires once the Vimeo player is ready
        onReady: (player: any) => handlePlayerReady(player),
    };

    return (
        <div
            className={
                customDivWrapperClasses
                    ? customDivWrapperClasses
                    : "mw-md-3xl mx-auto mb-8 position-relative"
            }
            id={id}
        >
            <Vimeo
                ref={playerRef}
                className="h-100 vimeo-iframe"
                {...vimeoProps}
            />

            {/* Gray overlay to simulate "click to play" if user hasn't pressed play yet.
          We skip it in live mode since it's always playing. */}
            {overlayVisible && !liveSimulation && (
                <div
                    className="overlay position-absolute top-0 start-0 w-100 h-100"
                    onClick={playVideo}
                    style={{background: "rgba(0,0,0,0.2)", cursor: "pointer"}}
                />
            )}

            {/* The original big play button if not in liveSimulation */}
            {playButtonVisible && !liveSimulation && (
                <div
                    className="position-absolute top-50 start-50 translate-middle text-success link-success play-button"
                    onClick={handlePlayClick}
                    style={{zIndex: 1, cursor: "pointer"}}
                >
                    <svg
                        width={64}
                        height={64}
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx={32} cy={32} r={32} fill="currentColor"/>
                        <path
                            d="M40.5 31.13L26.5 23.05C26.348 22.9622 26.1755 22.916 26 22.916C25.8245 22.916 25.652 22.9622 25.5 23.05C25.3474 23.1381 25.2208 23.265 25.133 23.4177C25.0452 23.5705 24.9993 23.7438 25 23.92V40.08C24.9993 40.2562 25.0452 40.4295 25.133 40.5823C25.2208 40.735 25.3474 40.8619 25.5 40.95C25.652 41.0378 25.8245 41.084 26 41.084C26.1755 41.084 26.348 41.0378 26.5 40.95L40.5 32.87C40.6539 32.7828 40.7819 32.6563 40.871 32.5035C40.96 32.3506 41.007 32.1769 41.007 32C41.007 31.8231 40.96 31.6494 40.871 31.4965C40.7819 31.3437 40.6539 31.2172 40.5 31.13ZM27 38.35V25.65L38 32L27 38.35Z"
                            fill="white"
                        />
                    </svg>
                </div>
            )}

            {/* "Włącz dźwięk" overlay if autoplay with sound was blocked */}
            {muteOverlayVisible && (
                <div
                    className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                    style={{background: "rgba(0,0,0,0.5)", zIndex: 2}}
                >
                    <button
                        className="btn btn-success btn-lg fw-bold"
                        onClick={handleUnmuteClick}
                    >
                        Włącz dźwięk
                    </button>
                </div>
            )}
        </div>
    );
}