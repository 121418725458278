import React from 'react';
import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import NewsletterForm, {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import AnimatedLink from "../../../components/ui/motion/AnimatedLink";
import ImageWithDotsAndCircle from "../../../components/ui/img/ImageWithDotsAndCircle";

export default function Planner() {
    return <>
        <Meta title='Darmowy planer'/>
        <MainDesign containerStyles="py-20 py-md-44">
            <div className='row align-items-center g-16'>
                <div className='col-12 col-md-7'>
                    <div className='p-2 mw-xl-2xl mx-xl-auto'>
                        <h1
                            className='font-heading mb-4 fs-5 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Odbierz darmowy planer
                        </h1>
                        <p className='mb-8 fs-8 fw-medium text-secondary-light'>
                            Zarządzaj skutecznie swoim czasem i w pełni wykorzystuj każdą chwilę!
                        </p>
                        <NewsletterForm type={NewsletterType.Planner}
                                        customSuccessMessage='Planer został wysłany pomyślnie.'/>
                        <h2
                            className='font-heading mb-2 mt-12 fs-6 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Jak to działa?
                        </h2>
                        <p className='mb-4 fs-9 fw-medium text-white'>
                            Chcesz efektywnie planować czas, bez niepotrzebnego stresu, napiętrzających się zadań oraz
                            chcesz porzucić uczucie zmarnowanego czasu raz na zawsze?
                        </p>
                        <p className='mb-1 fs-8 fw-medium text-success'>
                            Świetnie trafiłeś!
                        </p>
                        <p className='mb-8 fs-9 fw-medium text-white'>
                            Specjalnie dla Ciebie stworzyliśmy <span className='text-success'> "Dzienny planer"</span>
                        </p>
                        <div className='mb-4 fs-9 fw-medium text-white'>
                            Znajdziesz w nim miejsce na:
                            <ul>
                                <li className='mb-1 mt-1 fs-10'>
                                    codzienny cel
                                </li>
                                <li className='mb-1 fs-10'>
                                    afirmację - czas zmienić swoje przekonania
                                </li>
                                <li className='mb-1 fs-10'>
                                    listę zadań
                                </li>
                                <li className='mb-1 fs-10'>
                                    miejsce na wyrażenie wdzięczności
                                </li>
                                <li className='mb-1 fs-10'>
                                    miejsce na zapisanie swoich codziennych wygranych
                                </li>
                            </ul>
                        </div>

                        <p className='mb-8 fs-10 fw-medium text-secondary-light'>
                            Przeczytaj więcej o planowaniu dnia na <AnimatedLink
                            to='https://produkacja.pl/blog/2ddto6CzDOajX7eOpwkQVLyDyiq'
                            className='fw-bold text-success'>
                            naszym blogu
                        </AnimatedLink>.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-md-5'>
                    <ImageWithDotsAndCircle
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/marketing-happy-student-planner-photo.webp'/>
                </div>
                <div className='col-12'>
                    <div className='p-2 mw-xl-7xl mx-xl-auto'>
                        <h1
                            className='font-heading mb-4 fs-5 text-white mt-5'
                            style={{letterSpacing: '-1px'}}
                        >
                            Jak wygląda taki planer?
                        </h1>
                        <p className='mb-8 fs-9 fw-medium text-secondary-light'>
                            Zobacz przykładowe wypełnienie wraz z wyjaśnieniami.
                        </p>
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/marketing/planer/example-planer.svg'
                            className='img-fluid'
                            alt='planer'/>

                        <div className='p-2 mw-xl-5xl mx-xl-auto'>
                            <h2
                                className='font-heading mb-2 mt-12 fs-6 text-white text-center'
                                style={{letterSpacing: '-1px'}}
                            >
                                Chcę skutecznie planować - odbieram swój planer
                            </h2>
                        </div>

                        <div className='p-2 mw-xl-2xl mx-xl-auto'>
                            <NewsletterForm type={NewsletterType.Planner}
                                            customSuccessMessage='Planer został wysłany pomyślnie.'/>
                        </div>
                    </div>
                </div>
            </div>
        </MainDesign>
    </>
}



