import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {AddChatMessage, UpdateWebinarStatistics, WebinarDetails} from "../model/user/webinar/WebinarDetails";

export class WebinarService {

    public static getWebinarDetails(ksuid: string): Promise<WebinarDetails> {
        return HttpService.sendRequest<WebinarDetails>(
            HttpMethod.GET,
            `/api/webinar/${ksuid}`,
        );
    }

    public static addChatMessage(requestBody: AddChatMessage): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/webinar/add_chat_message',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static updateWebinarStatistics(requestBody: UpdateWebinarStatistics): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/webinar/update_webinar_statistics',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }
}
