import AnimatedLink from "../../../../ui/motion/AnimatedLink";
import React from "react";
import {TraceService} from "../../../../../service/TraceService";
import {TraceType} from "../../../../../model/user/general/Trace";

interface ChatBenefitsProps {
    isLoggedIn: boolean
}

export default function ChatBenefits(props: ChatBenefitsProps) {
    const {isLoggedIn} = props
    return <>
        <div className="mb-16 text-center mw-md-3xl mx-auto">
                <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                Korzyści
                </span>

            <h2
                className='font-heading mb-4 fs-4 text-white'
                style={{letterSpacing: '-1px'}}
            >
                Kluczowe korzyści
            </h2>
            <p className='fs-10 fw-medium text-secondary-light mb-1'>
                Dlaczego warto korzystać z naszego chatu edukacyjnego?
            </p>
            <p className='fs-10 fw-medium text-secondary-light mb-3'>
                Jakie realne zyski stoją za inwestycją w nasze narzędzie?
            </p>
            <p className='fs-10 fw-medium text-secondary-light'>
                Oto kilka przykładów:
            </p>

        </div>
        <div className='row g-8 mt-10 mb-4'>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/dostepnosc.png'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Dostępność 24/7
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Ucz się i rozwijaj w dowolnym miejscu i czasie.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/baza+wiedzy.png'
                            alt='stress relief' style={{width: 33, height: 33}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Kompleksowa baza wiedzy
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Zdobywaj informacje szybko i łatwo, wszystko w jednym miejscu.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/efektywna+nauka.png'
                            alt='stress relief' style={{width: 33, height: 33}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Efektywna nauka
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Zwiększ wydajność swojej nauki używając sprawdzonych technik oraz nowoczesnych narzędzi.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/indywizualne+podejscie.png'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Indywidualnie podejście
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Dostosuj tempo i poziom przekazywanych informacji do Twoich potrzeb.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/feedback.png'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Natychmiastowy feedback
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Uzyskaj natychmiastową analizę odpowiedzi i tekstów, pozwalającą od razu korygować błędy.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/motywacja.png'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Motywacja do nauki
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Angażująca i przystępna forma nauki
                    </p>
                </div>
            </div>
            <div className='row mt-7 mw-3xl mx-auto px-1'>
                <div className='col-12 col-md-6 mb-8'>
                    <div className='mw-md-sm'>
                        <div
                            className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                            style={{width: 48, height: 48}}
                        >
                            <img
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/oszczednosc+czasu.png'
                                alt='stress relief' style={{width: 36, height: 36}}/>
                        </div>
                        <h3 className='mb-2 font-heading fs-9 text-white'>
                            Oszczędność czasu
                        </h3>
                        <p className='mb-0 text-secondary-light fw-medium'>
                            Nie trać czasu na dojazdy do korepetytorów, wyszukiwanie informacji czy oczekiwanie na
                            feedback.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mw-md-sm'>
                        <div
                            className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                            style={{width: 48, height: 48}}
                        >
                            <img
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-chat/zdolnosci+pisarskie.png'
                                alt='stress relief' style={{width: 34, height: 34}}/>
                        </div>
                        <h3 className='mb-2 font-heading fs-9 text-white'>
                            Rozwój umiejętności pisarskich
                        </h3>
                        <p className='mb-0 text-secondary-light fw-medium'>
                            Zyskaj dogłębne analizy swoich tekstów wraz z sugestiami udoskonalenia słownictwa i struktur
                            gramatycznych.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center g-4 mb-20">
            <div className="col-12 col-md-auto">
                <AnimatedLink
                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                    onClick={() => {
                        TraceService.addTrace(TraceType.BtnClick, 'btn-5 -> przetestuj za darmo już teraz')
                    }}
                    to={isLoggedIn ? '/chat' : '/rejestracja'}>
                    Przetestuj za darmo już teraz
                </AnimatedLink>
            </div>
        </div>
    </>
}