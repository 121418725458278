import React from "react";

export default function LearningCourseSuccessfulPurchase() {
    return <div className='mw-md-4xl mx-auto text-center mt-16'>
        <h2 className='fs-8 mt-8 text-warning'>
            Pytania / problemy?
        </h2>
        <p className='fw-medium mb-16 text-white'>
            Napisz do nas na adres <a
            href="mailto:kontakt@produkacja.pl"
            className="text-success link-success"
        >
            kontakt@produkacja.pl
        </a>
        </p>
    </div>
}