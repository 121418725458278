import AnimatedLink from "../../ui/motion/AnimatedLink";
import {useLocation} from "react-router-dom";

const selectedItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-success link-success bg-success bg-opacity-25 shadow'
const otherItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-secondary link-secondary-light'

export default function CommunityNavbar() {
    const location = useLocation();
    return <div className='mw-md-lg mx-auto'>
        <ul className='mb-2 mb-md-4 nav nav-pills'>
            <li>
                <AnimatedLink
                    className={location.pathname === '/produkt/spolecznosc' ? selectedItemStyles : otherItemStyles}
                    to='/produkt/spolecznosc'>
                    Discord (Forum)
                </AnimatedLink>
            </li>
            <li>
                <AnimatedLink
                    className={location.pathname === '/produkt/spolecznosc/spotkania' ? selectedItemStyles : otherItemStyles}
                    to='/produkt/spolecznosc/spotkania'>
                    Cotygodniowe spotkania online
                </AnimatedLink>
            </li>
        </ul>
        <hr className='text-white'/>
    </div>
}