import AdvantageItem from "../../../ui/item/AdvantageItem";
import {Form, Link} from "react-router-dom";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import React, {useEffect, useState} from "react";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import {IndividualKedOffer} from "../../../../model/user/purchase/IndividualKedOffer";
import {formatDate} from "../../../../util/DateUtil";

interface LearningCoursePurchaseProps {
    individualKedOffer?: IndividualKedOffer;
}

// Helper to format remaining seconds into HHh MMm SSs
function formatTimeLeft(totalSeconds: number): string {
    if (totalSeconds <= 0) {
        return "0s";
    }
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`;
    if (minutes > 0) return `${minutes}m ${seconds}s`;
    return `${seconds}s`;
}

/**
 * Returns how many full seconds remain until `expiresAt` minus the current time.
 * If `expiresAt` is not provided, returns undefined.
 */
function getInitialDiffSeconds(expiresAt?: string | Date): number | undefined {
    if (!expiresAt) return undefined;
    const diffMs = new Date(expiresAt).getTime() - Date.now();
    return Math.floor(diffMs / 1000);
}

export default function LearningCoursePurchase(props: LearningCoursePurchaseProps) {
    const {individualKedOffer} = props;
    const {isSubmitting} = useSubmitFormAction();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    // Calculate immediate diffSeconds on mount
    const initialDiffSeconds = getInitialDiffSeconds(individualKedOffer?.expiresAt);

    // If there's no expiresAt, or the time is still positive, show countdown. Otherwise, it's expired.
    const [timeLeft, setTimeLeft] = useState(
        initialDiffSeconds !== undefined && initialDiffSeconds > 0
            ? formatTimeLeft(initialDiffSeconds)
            : '0s'
    );

    const [isExpired, setIsExpired] = useState(
        initialDiffSeconds !== undefined ? initialDiffSeconds <= 0 : false
    );

    // UseEffect to keep updating in real-time if there is an expiresAt.
    useEffect(() => {
        if (!individualKedOffer?.expiresAt) return;

        let timerId: NodeJS.Timeout | undefined;

        timerId = setInterval(() => {
            const diffSeconds = Math.floor(
                new Date(individualKedOffer!!.expiresAt!!).getTime() - Date.now()
            ) / 1000;

            if (diffSeconds <= 0) {
                setTimeLeft('0s');
                setIsExpired(true);
                clearInterval(timerId);
            } else {
                setTimeLeft(formatTimeLeft(diffSeconds));
            }
        }, 1000);

        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, [individualKedOffer?.expiresAt]);

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const isInputValid = isEmailValid && name.trim().length >= 2;

    return (
        <>
            {individualKedOffer && (
                <>
                    <hr className='text-white'/>
                    <h1 className='mw-md-4xl mx-auto text-center'>
                        Indywidualna oferta dla{" "}
                        <span className='fw-bold text-success text-decoration-none'>
                            {individualKedOffer.name}
                        </span>
                    </h1>

                    {/* Countdown or expiry message */}
                    {individualKedOffer.expiresAt && !isExpired && (
                        <div className="text-center text-warning fw-medium fs-8 mt-2">
                            Termin płatności upływa za: {timeLeft} (
                            {formatDate(individualKedOffer.expiresAt)})
                        </div>
                    )}
                    {individualKedOffer.expiresAt && isExpired && (
                        <div className="text-center text-warning fw-medium fs-8 mt-2">
                            Termin płatności: {formatDate(individualKedOffer.expiresAt)}
                        </div>
                    )}

                    <hr className='text-white'/>
                </>
            )}

            {/* If the time is expired, show smaller text about contacting support below the <hr/> */}
            {isExpired && individualKedOffer?.expiresAt && (
                <div className="mw-md-3xl mx-auto text-center text-white-50 fs-9 fw-medium my-4">
                    Termin płatności upłynął. Jeśli chciałeś/-aś zapłacić, ale czas się skończył,
                    skontaktuj się z nami pod adresem{" "}
                    <a
                        href="mailto:kontakt@produkacja.pl"
                        className="text-success link-success"
                    >
                        kontakt@produkacja.pl
                    </a> <br/> Każda sytuacja będzie rozpatrywana indywidualnie.
                </div>
            )}

            {/* Hide benefits, additional comments, and form if expired */}
            {!isExpired && (
                <div
                    className={`mw-md-xl mx-auto text-white ${
                        individualKedOffer ? 'mt-12' : 'mt-16'
                    }`}
                >
                    <h2 className='mb-4'>Co zyskujesz?</h2>
                    {individualKedOffer ? (
                        individualKedOffer.benefits.map((benefit, index) => (
                            <AdvantageItem key={index} title={benefit}/>
                        ))
                    ) : (
                        <>
                            <AdvantageItem
                                title='Roczny dostęp do kursu efektywnego działania (liczony od października 2024).'
                            />
                            <AdvantageItem title='Cotygodniowe grupowe spotkania na Zoomie.'/>
                            <AdvantageItem title='5 spotkań indywidualnych, dopasowanych do Twoich potrzeb.'/>
                            <AdvantageItem
                                title='Dostęp do forum, na którym można zadawać nieograniczoną liczbę pytań dotyczących zagadnień z kursu.'
                            />
                        </>
                    )}

                    {individualKedOffer?.additionalComment && (
                        <>
                            <h3 className='mt-6'>Dodatkowe ustalenia, komentarze</h3>
                            <p>
                                {individualKedOffer.additionalComment
                                    .split('\n')
                                    .map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index <
                                                individualKedOffer.additionalComment.split('\n')
                                                    .length -
                                                1 && <br/>}
                                        </React.Fragment>
                                    ))}
                            </p>
                        </>
                    )}

                    <Form method="POST" className='mw-md-xl mx-auto border-light rounded shadow p-1'>
                        <p
                            className={`fw-medium fs-10 ${
                                individualKedOffer?.additionalComment ? 'mt-8' : 'mt-10'
                            }`}
                        >
                            Poniżej podaj dane osoby,{" "}
                            <span className='fw-bold'>dla której</span> kupujesz dostęp do kursu.
                        </p>
                        <div className='mb-4 row g-6'>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label
                                        className='mb-1 fw-medium text-white'
                                        htmlFor='purchase-course-name-input'
                                    >
                                        Imię
                                    </label>
                                    <input
                                        className='form-control text-secondary-dark shadow'
                                        id='purchase-course-name-input'
                                        type='text'
                                        placeholder='Podaj imię'
                                        name='name'
                                        autoComplete='name'
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label
                                        className='mb-1 fw-medium text-white'
                                        htmlFor='purchase-course-email-input'
                                    >
                                        Email
                                    </label>
                                    <input
                                        className='form-control text-secondary-dark shadow'
                                        id='purchase-course-email-input'
                                        type='email'
                                        placeholder='Podaj adres email'
                                        name='email'
                                        autoComplete='email'
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
                            <span>Przechodząc dalej akceptujesz </span>
                            <Link className='text-success link-success' to='/polityka-prywatnosci'>
                                politykę prywatności
                            </Link>
                            <span>, </span>
                            <Link className='text-success link-success' to='/regulamin'>
                                regulamin
                            </Link>
                            <span> oraz </span>
                            <Link className='text-success link-success' to='/regulamin-ked'>
                                regulamin KED
                            </Link>
                            <span>. </span>
                            {individualKedOffer && (
                                <span>
                                    Powyższe ustalenia mają wyższy priorytet niż regulamin KED.
                                </span>
                            )}
                        </p>
                        <div className='mb-3 row g-4'>
                            <div className='col-12'>
                                <AnimatedButton
                                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                                    disabled={isSubmitting || !isInputValid}
                                    customScale={1.05}
                                >
                                    {isSubmitting ? 'Proszę czekać...' : 'Kontynuuj'}
                                </AnimatedButton>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </>
    );
}