import AnimatedLink from "../motion/AnimatedLink";
import React from "react";

interface SmallSquareLogoProps {
    width?: string
    disableLink?: boolean
    customRedirect?: string
}

export default function SmallSquareLogo(props: SmallSquareLogoProps) {
    const {width, disableLink, customRedirect} = props;
    return <div className="d-flex justify-content-center mb-2">
        {
            disableLink ? <img className="img-fluid" style={{width: width ?? '48px'}}
                               src="https://produkacja.s3.eu-central-1.amazonaws.com/web/logo/logo-500-500-raw.png"
                               alt='logo'/> : <AnimatedLink to={customRedirect ?? "/"}>
                <img className="img-fluid" style={{width: width ?? '48px'}}
                     src="https://produkacja.s3.eu-central-1.amazonaws.com/web/logo/logo-500-500-raw.png" alt=''/>
            </AnimatedLink>
        }

    </div>
}