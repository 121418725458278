import AnimatedButton from "../../ui/motion/AnimatedButton";
import React from "react";
import CopyToClipIcon from "../../ui/element/CopyToClipIcon";
import {useToast} from "../../ui/toast/ToastContext";
import Heading from "../../common/header/Heading";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import CommunityNavbar from "./CommunityNavbar";

interface CommunityProps {
    isEffectiveActionCourseSubscriber: boolean
}

const DISCORD_LINK = "https://discord.gg/Fkesj2ue5Y";

export default function Community({isEffectiveActionCourseSubscriber}: CommunityProps) {
    const {showMessage} = useToast();

    async function copyToClip() {
        await navigator.clipboard.writeText(DISCORD_LINK);
        showMessage("Pomyślnie skopiowano.");
    }

    return <>
        <Heading title='Dołącz na Discorda!' badgeText='Społeczność'/>
        {
            isEffectiveActionCourseSubscriber && <CommunityNavbar/>
        }


        {
            isEffectiveActionCourseSubscriber &&
            <p className="mb-0 mt-6 fs-11 text-center fw-medium text-white mw-md-lg mx-auto"><span
                className='text-warning fw-bold'>UWAGA:</span> Aby uzyskać
                dostęp do kanałów związanych z Kursem Efektywnego Działania, napisz nam wiadomość na maila
                kontakt@produkacja.pl wraz ze swoją nazwą użytkownika na Discordzie.
            </p>
        }
        <div
            className={`row justify-content-center g-2 mt-6 mw-md-xs mx-auto`}>

            <AnimatedLink
                openLinkInNewTab={true}
                className="btn btn-success btn-lg"
                to={DISCORD_LINK}
            >
                Dołączam
            </AnimatedLink>

            <p className="mb-0 mt-6 fs-10 text-center fw-medium text-secondary-light">Lub skorzystaj z linku...</p>
            <input
                type="text"
                className="form-control form-control-lg flex-grow-1 bg-dark"
                value={DISCORD_LINK}
                readOnly
                style={{
                    padding: '1rem 0.75rem',
                    color: "white",
                    textAlign: "center"
                }}
            />
            <AnimatedButton
                className="btn btn-outline-light btn-sm flex-grow-0"
                type="button"
                onClick={copyToClip}
                style={{whiteSpace: 'nowrap'}} // Ensure text stays in one line
            >
                <CopyToClipIcon/>
                <span>Skopiuj zaproszenie</span>
            </AnimatedButton>
        </div>
        <p className="mb-0 mt-6 fs-11 text-center fw-medium text-warning mw-md-md mx-auto">Na Discorda przenieśliśmy się
            dopiero 21.01.25, więc jeżeli jeszcze nie dołączyłeś/aś, zrób to teraz.
        </p>
        <p className="mb-0 mt-6 fs-11 text-center fw-medium text-white mw-md-md mx-auto">Na Discordzie uzyskasz szybką
            pomoc, a także nawiążesz nowe, pozytywne znajomości. 😎
        </p>

    </>
}