// Root.tsx
import {Outlet} from "react-router-dom";
import Footer from "../components/common/Footer";
import ScrollToTop from "../util/ScrollToTop";
import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import CookieBanner from "../components/common/CookieBanner";
import UserHeader from "../components/user/UserHeader";
import {TraceService} from "../service/TraceService";
import {TraceType} from "../model/user/general/Trace";
import {DiagnosticService} from "../service/DiagnosticService";
import ReactPixel from 'react-facebook-pixel';

interface RootLayoutProps {
    customHeader?: JSX.Element,
    customFooter?: JSX.Element,
}

export default function RootLayout({customHeader, customFooter}: RootLayoutProps) {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    useEffect(() => {
        // If cookie consent is already given, load scripts immediately
        if (cookies.cookieConsent) {
            loadGoogleAnalytics();
            activateFbPixel();
        } else {
            ReactPixel.init('1721779321916461', undefined, {debug: false, autoConfig: true});
            ReactPixel.revokeConsent();
        }
    }, [cookies]);

    function addCookieCookiesConsent(consent: boolean) {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        setCookie("cookieConsent", consent, {path: "/", expires: oneYearFromNow});
        TraceService.addTrace(TraceType.CookieConsent, JSON.stringify({consent: consent}));
    }

    function giveCookieConsent() {
        addCookieCookiesConsent(true);
        loadGoogleAnalytics()
    }

    function rejectCookieConsent() {
        addCookieCookiesConsent(false);
    }

    function loadGoogleAnalytics() {
        try {
            // @ts-ignore
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        } catch (e) {
            DiagnosticService.addDiagnostic({
                functionName: "cookie-consent",
                error: e,
                fullEvent: {},
                additionalInfo: 'error while granting consent for google ads'
            })
        }
    }

    function activateFbPixel() {
        ReactPixel.init('1721779321916461', undefined, {debug: false, autoConfig: true});
        ReactPixel.grantConsent();
    }

    return (
        <>
            <ScrollToTop/>
            {
                customHeader ? customHeader : <UserHeader/>
            }
            <Outlet/>
            {
                customFooter ? customFooter : <Footer/>
            }
            {(cookies.cookieConsent === undefined || cookies.cookieConsent === null) &&
                <CookieBanner giveCookieConsent={giveCookieConsent} rejectCookieConsent={rejectCookieConsent}/>}
        </>
    );
}
