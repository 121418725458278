import Header from "../ui/header/Header";

export default function UserHeader() {
    return <Header
        loaderId='root'
        topItems={[
            {
                title: 'O nas',
                to: '/o-nas'
            },
            {
                title: 'Oferta',
                to: '/oferta'
            },
            {
                title: 'Blog',
                to: '/blog'
            },
            {
                title: 'Kontakt',
                to: '/kontakt'
            }
        ]}
        dropdownItems={[
            {
                title: 'Kurs Efektywnego Działania',
                to: '/produkt/kurs-efektywnego-dzialania'
            },
            {
                title: 'Chat',
                to: '/produkt/chat'
            },
            {
                title: 'Lista transakcji',
                to: '/transakcje'
            }
        ]}
    />
}