import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {MW_FULL_FUNNEL_EXPERIMENT_2} from "../../../util/ExperimentUtil";
import React from "react";
import usePageTracking from "../../../hooks/usePageTracking";
import useOptInForm from "../../../hooks/useOptInForm";
import ProdukacjaCopyrightNoteWithPhoneNumber from "../../../components/funnel/ProdukacjaCopyrightNoteWithPhoneNumber";
import WebinarOptIn from "../../../components/funnel/optin/WebinarOptIn";

export default function WebinarOptInPage() {
    usePageTracking();

    const {
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        email,
        setEmail,
        name,
        setName,
        isInputValid,
        handleButtonClick,
    } = useOptInForm(MW_FULL_FUNNEL_EXPERIMENT_2);

    return (
        <>
            <Meta
                title="Zapisz się na webinar."
                description="Zarejestruj się na bezpłatny webinar pod tytułem 'Jak zostać NAJLEPSZYM uczniem' (dla rodziców)."
                imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/webinar/webinar-optin-icon.png'
            />
            <MainDesign containerStyles="container pt-10 pt-md-20 pb-10" minHeight='100vh'>
                <WebinarOptIn handleButtonClick={handleButtonClick}
                              email={email} name={name} setName={setName}
                              setEmail={setEmail}
                              confirmModalOpen={confirmModalOpen}
                              setConfirmModalOpen={setConfirmModalOpen}
                              isInputValid={isInputValid}
                              loading={loading}/>
                <ProdukacjaCopyrightNoteWithPhoneNumber/>
            </MainDesign>
        </>
    );
}
