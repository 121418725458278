import React, {lazy, Suspense, useEffect, useState} from 'react';
import {ExperimentMetrics} from '../../../model/admin/Metrics';
import LoadingPage from "../../../pages/common/LoadingPage";

const LazyBar = lazy(() => import('react-chartjs-2').then(module => ({default: module.Bar})));

export interface AdminKedExperimentResultsProps {
    salesCallExperimentMetrics: ExperimentMetrics[];
    optInExperimentMetrics: ExperimentMetrics[];
    optInExperimentMetrics2: ExperimentMetrics[];
    optInExperimentMetrics3: ExperimentMetrics[];
    directSalesPageOrVsl: ExperimentMetrics[];
    fbDirectAdsMetrics: ExperimentMetrics[];
    mwMetrics: ExperimentMetrics[];
    fbDirectAdsMetrics2: ExperimentMetrics[];
    fullFunnelAdsMetrics: ExperimentMetrics[];
    fullFunnelAdsMetrics2: ExperimentMetrics[];
    kedWebinarExperiment1: ExperimentMetrics[];
}

export default function AdminKedExperimentResults(props: AdminKedExperimentResultsProps) {
    const {
        salesCallExperimentMetrics,
        optInExperimentMetrics,
        optInExperimentMetrics2,
        optInExperimentMetrics3,
        directSalesPageOrVsl,
        fbDirectAdsMetrics,
        mwMetrics,
        fbDirectAdsMetrics2,
        fullFunnelAdsMetrics,
        fullFunnelAdsMetrics2,
        kedWebinarExperiment1
    } = props;
    const [chartJsRegistered, setChartJsRegistered] = useState(false);

    useEffect(() => {
        const loadChartJs = async () => {
            const {Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} = await import('chart.js');
            Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
            setChartJsRegistered(true);
        };

        loadChartJs();
    }, []);

    const createChartData = (metrics: ExperimentMetrics[]) => {
        return metrics.map(metric => ({
            labels: ['All', 'A', 'B'],
            datasets: [
                {
                    label: metric.traceType,
                    data: [metric.all, metric.a, metric.b],
                    backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
                    borderColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
                    borderWidth: 1,
                },
            ],
        }));
    };

    const renderCharts = (metrics: ExperimentMetrics[], title: string) => {
        const chartData = createChartData(metrics);
        return (
            <div className='text-center mb-10'>
                <h2>{title}</h2>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {chartData.map((data, index) => (
                        <div key={index} style={{width: '300px', height: '300px', margin: '10px'}}>
                            <h3 style={{fontSize: '14px', textAlign: 'center'}}>{metrics[index].experimentId}</h3>
                            <Suspense fallback={<LoadingPage/>}>
                                {chartJsRegistered &&
                                    <LazyBar data={data} options={{responsive: true, maintainAspectRatio: false}}/>}
                            </Suspense>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return <>

        <div>
            {renderCharts(kedWebinarExperiment1, 'Webinar V1 - 16.01')}
            <hr className='text-white'/>
            {renderCharts(fullFunnelAdsMetrics2, 'Full Funnel 03.11')}
            <hr className='text-white'/>
            {renderCharts(fullFunnelAdsMetrics, 'Full Funnel 05.10')}
            <hr className='text-white'/>
        </div>
    </>;
}