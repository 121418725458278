import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {
    getCurrentlyLoggedInUser,
    redirectIfNotEffectiveActionCourseSubscriber,
    TokenPayload
} from "../../../util/AuthUtil";
import CommunityWeeklyMeetings from "../../../components/user/products/CommunityWeeklyMeetings";
import {useLoaderData} from "react-router-dom";

export default function CommunityWeeklyMeetingPage() {
    const payload = useLoaderData() as TokenPayload;

    return <>
        <Meta title='Cotygodniowe spotkania'/>
        <MainDesign containerStyles='container py-20'>
            <CommunityWeeklyMeetings payload={payload}/>
        </MainDesign>
    </>
}

export async function loader(): Promise<Response | TokenPayload | null> {
    const redirect = await redirectIfNotEffectiveActionCourseSubscriber();
    if (redirect) {
        return redirect;
    } else {
        return await getCurrentlyLoggedInUser();
    }
}
