// Chat.tsx
import React, {KeyboardEvent} from "react";

/** Your ChatMessage type */
export interface ChatMessage {
    text: string;
    isUser: boolean;
    timestamp: Date;
    userName?: string;
    fromOrganizer?: boolean;
}

export interface ChatProps {
    chatContainerRef: React.RefObject<HTMLDivElement>;
    chatMessages: ChatMessage[];
    userInput: string;
    setUserInput: React.Dispatch<React.SetStateAction<string>>;
    handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    handleSend: () => void;
}

export default function Chat({
                                 chatContainerRef,
                                 chatMessages,
                                 userInput,
                                 setUserInput,
                                 handleKeyDown,
                                 handleSend,
                             }: ChatProps) {
    return (
        <>
            {/*
        This .flex-column container is pinned absolutely
        by the PARENT which does the aspect-ratio hack.
        We use 100% height here so it fully occupies
        that pinned area, but doesn’t exceed it.
      */}
            <div
                className="bg-light-dark d-flex flex-column p-3 rounded-3"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                {/*
          The scrollable area for messages
          - .flex-grow-1 so it expands
          - .overflow-auto to let the user scroll
        */}
                <div
                    ref={chatContainerRef}
                    className="flex-grow-1 overflow-auto mb-3 p-2"
                    style={{border: "1px solid #ccc", borderRadius: "4px"}}
                >
                    {chatMessages.map((msg, index) => {
                        const isOrganizer = msg.fromOrganizer === true;
                        return (
                            <div
                                key={index}
                                className={`mb-3 ${msg.isUser ? "text-end" : "text-start"}`}
                            >
                                <div
                                    className="d-inline-block p-2"
                                    style={{
                                        background: msg.isUser ? "#0d6efd" : "#6c757d",
                                        color: "#fff",
                                        borderRadius: "12px",
                                        maxWidth: "80%",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {isOrganizer && (
                                        <div className="fw-bold text-danger">
                                            {msg.userName || "Organizator"}
                                        </div>
                                    )}
                                    {!isOrganizer && msg.userName && (
                                        <div className="fw-bold">{msg.userName}</div>
                                    )}
                                    <div>{msg.text}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/*
          The message input row pinned at the bottom
          of the flex-column.
        */}
                <div className="d-flex">
                    <input
                        type="text"
                        className="form-control me-2"
                        placeholder="Napisz wiadomość..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <button className="btn btn-success" onClick={handleSend}>
                        Wyślij
                    </button>
                </div>
            </div>
        </>
    );
}