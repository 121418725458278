import {useEffect} from 'react';
import {FetcherWithComponents, useActionData, useNavigate, useNavigation} from 'react-router-dom';
import {ActionResponse} from "../App";
import {useToast} from "../components/ui/toast/ToastContext";

export function useSubmitFormAction(successRedirectPath = '/',
                                    fetcher: FetcherWithComponents<any> | null = null,
                                    callIfSuccess?: (body: string) => void,
                                    redirect: boolean = true,
                                    customMessage?: (body: string, status: number) => string,
                                    callIfFailure?: (body: string) => void,
                                    hideMessageToast: boolean = false) {
    const {showMessage} = useToast();
    const navigate = useNavigate();
    const navigation = useNavigation();
    let actionData = useActionData();
    let isSubmitting = navigation.state === 'submitting';

    if (fetcher) {
        actionData = fetcher.data;
        isSubmitting = fetcher.state === 'submitting';
    }

    useEffect(() => {
        if (actionData) {
            const {body, status} = actionData as ActionResponse;
            if (body) {
                if (!hideMessageToast) {
                    if (customMessage) {
                        const message = customMessage(body, status);
                        showMessage(message);
                    } else {
                        showMessage(body);
                    }
                }
            }
            if (status === 200 || status === 201) {
                if (redirect) {
                    navigate(successRedirectPath);
                }
                if (callIfSuccess) {
                    callIfSuccess(body);
                }
            } else {
                if (callIfFailure) {
                    callIfFailure(body);
                }
            }
        }
    }, [actionData, navigate, showMessage, successRedirectPath]);

    return {isSubmitting};
}
