interface SalesPageNumbersItemProps {
    metricValue: number | string
    metricTitle: string
}

export default function SalesPageNumbersItem(props: SalesPageNumbersItemProps) {
    const {metricValue, metricTitle} = props;
    return <div className='col-12 col-md-4 mt-8 mb-4 mt-md-0 mt-mb-0'>
        <div className='text-center'>
            <h2
                className={`mb-2 font-heading text-success fs-4`}
                style={{letterSpacing: '-1px'}}
            >
                {metricValue}
            </h2>
            <p className='mb-0 fs-8 text-secondary-light fw-medium'>
                {metricTitle}
            </p>
        </div>
    </div>
}