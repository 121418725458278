import React from 'react'

interface ResponsiveElementProps {
    mobileClasses: string
    desktopClasses: string
    element: JSX.Element
}

export default function ResponsiveElement(props: ResponsiveElementProps) {
    const {mobileClasses, desktopClasses, element} = props

    return (
        <>
            {React.cloneElement(element, {
                className: `d-none d-md-block ${desktopClasses} ${
                    element.props.className ?? ''
                }`,
            })}
            {React.cloneElement(element, {
                className: `d-md-none ${mobileClasses} ${element.props.className ?? ''}`,
            })}
        </>
    )
}