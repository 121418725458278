import MainDesign from "../../../pages/MainDesign";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import SmallSquareLogo from "../../ui/logo/SquareLogo";

export default function ProductsFooter() {
    return <>
        <MainDesign minHeight="300px" containerStyles="container pt-20" removeSectionOverflowHidden={true}
                    hideBackgroundImage={true} minHeightChildrenDiv='300px' disableAnimation={true}>
            <SmallSquareLogo width='80px' customRedirect='/produkt'/>
            <ul className="nav justify-content-center mb-5">
                <li className="nav-item"><AnimatedLink
                    className="nav-link px-6 pt-4 fs-11 link-secondary-light"
                    to='/polityka-prywatnosci'>Polityka prywatności</AnimatedLink></li>
                <li className="nav-item"><AnimatedLink
                    className="nav-link px-6 pt-4 fs-11 link-secondary-light"
                    to='/regulamin'>Regulamin</AnimatedLink></li>
                <li className="nav-item"><AnimatedLink
                    className="nav-link px-6 pt-4 fs-11 link-secondary-light"
                    to='/regulamin-ked'>Regulamin KED</AnimatedLink></li>
            </ul>
            <p className='mb-0 pb-8 fs-12 fw-medium text-center text-secondary-light'>
                <span className='text-success'>Masz pytanie?</span> kontakt@produkacja.pl
            </p>

            <div
                className='position-relative border-bottom border-light-dark'
            />
            <div className="position-relative container" style={{zIndex: 1}}>
                <p className="mb-0 pt-8 pb-8 fs-11 fw-medium text-center text-secondary-light">© 2025
                    Produkacja | Wszelkie prawa zastrzeżone.</p>
            </div>
        </MainDesign>
    </>
}