import {format, fromUnixTime, isValid, parseISO} from "date-fns";

export function formatDate(rawDate: string) {
    return rawDate && isValid(parseISO(rawDate))
        ? format(parseISO(rawDate), 'dd.MM.yyyy HH:mm')
        : ''
}

export function formatUnixTimestamp(timestamp: number) {
    const date = fromUnixTime(timestamp / 1000); // Convert milliseconds to seconds
    return isValid(date)
        ? format(date, 'dd.MM.yyyy')
        : '';
}

export function formatISODateString(dateString: string) {
    const date = parseISO(dateString); // Parse the ISO date string
    return format(date, 'dd.MM.yyyy'); // Format to your desired format
}

export function formatDateWithHourWord(rawDate: string) {
    const formattedDate = formatDate(rawDate);
    if (formattedDate) {
        const dateParts = formattedDate.split(' ');
        const day = dateParts[0];
        const hour = dateParts[1];
        return `${day} o godzinie ${hour}`;
    }
    return '';
}

export function formatDateWithOWord(rawDate: string) {
    const formattedDate = formatDate(rawDate);
    if (formattedDate) {
        const dateParts = formattedDate.split(' ');
        const day = dateParts[0];
        const hour = dateParts[1];
        return `${day} o ${hour}`;
    }
    return '';
}