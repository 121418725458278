export const learningCourseStructure = [
    {
        title: "Wprowadzenie",
        episodes: [
            {
                title: "Witaj na kursie!",
                vimeoUrl: 'https://vimeo.com/1048286825',
                description: "Witaj na Kursie Efektywnego Działania!<br/><br/>" +
                    "Nagrałem ten krótki film, aby pogratulować Ci podjęcia doskonałej decyzji i osobiście " +
                    "przywitać Cię w programie. <br/>" +
                    "W tygodniu zerowym przejdziemy sobie przez sprawy organizacyjne i pokażę Ci, jak wynieść 100% z " +
                    "kursu. <br/><br/> " +
                    "Bardzo się cieszę, że tutaj jesteś i życzę Ci powodzenia w stawaniu się najlepszą wersją siebie!" +
                    "<br/> <i>-Mikołaj, Twój główny instruktor</i>"
            },
            {
                title: "Jak wygląda kurs?",
                description: "Kurs składa się z 9 tygodni. <br/> Zaczynamy od budowania świadomości, przez podstawy efektywnego działania i efektywną naukę, aż po wejście na wyższy poziom i stanie się najlepszą wersją siebie.<br/><br/>" +
                    "Pełny opis poszczególnych tygodni znajduje się <a class='text-success text-decoration-underline' href='/produkt/kurs-efektywnego-dzialania'>tutaj</a>. <br/><br/>" +
                    "Każdy kolejny tydzień zostanie odblokowany automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z poprzedniego tygodnia. <br/><br/>" +
                    "Kluczowe jest, aby wykonywać ćwiczenia oraz wprowadzać w życie zmiany. W przeciwnym razie stracisz tylko swój czas.<br/><br/>" +
                    "<i>\"Nic się nie zmieni, o ile Ty się nie zmieszni.\"",
            },
            {
                title: "Gdzie szukać pomocy?",
                description: "<ol>" +
                    "<li>Co tydzień odbywają się konsultacje na zoomie. Na ten moment, jest to sobota 17:00. Na spotkaniu odpowiadamy na pytania uczniów i pomagamy im w zrozumieniu i wdrożeniu w życie rzeczy z kursu. Więcej informacji znajdziesz po kliknięciu w link w sekcji \"Materiały\". </li>" +
                    "<br/>" +
                    "<li>Dołącz na naszego Discorda, gdzie poznasz nowe, ambitne osoby oraz uzyskasz szybką pomoc. Więcej informacji w sekcji \"Materiały\".</li>" +
                    "</ol> ",
                resources: [
                    {
                        title: "Cotygodniowe spotkania",
                        link: "/produkt/spolecznosc/spotkania"
                    },
                    {
                        title: "Discord",
                        link: "/produkt/spolecznosc"
                    }
                ]
            },
            {
                title: "Jak wynieść 100% z kursu?",
                description: "Aby wynieść z Kursu jak najwięcej, warto stosować się następujących zasad:<br/> " +
                    "<ol>" +
                    "<li class='mb-1 mt-3'>Regularnie pojawiaj się na spotkaniach na zoomie.</li>" +
                    "<li class='mb-1'>Dołącz na naszego Discorda, nawiązuj nowe znajomości i uzyskuj szybką pomoc.</li>" +
                    "<li class='mb-1'>Zawsze zachowaj otwarty umysł.</li>" +
                    "<li class='mb-1'>Po każdej lekcji zapisz swoimi słowami, czego się z niej nauczyłeś i jakie zmiany zamierzasz wprowadzić w swoim życiu.</li>" +
                    "<li class='mb-1'>Nie spiesz się. Lepiej poświęcić więcej czasu na praktykę niż tylko oglądać filmy. Nie ma czegoś takiego jak za dużo praktyki :)</li>" +
                    "<li class='mb-1'>Wykonuj ćwiczenia, o których jest mowa w filmach, nawet jeżeli wydaje Ci się, że nie jest Ci to potrzebne. Zobaczysz, że podziękujesz sobie.</li>" +
                    "<li class='mb-1'>Zaplanuj czas, w którym poświęcisz swoją uwagę tylko i wyłącznie na Kurs. Średnio będziesz potrzebować ~3h tygodniowo, aby obejrzeć wszystkie filmy i wprowadzić w życie zmiany. </li>" +
                    "</ol><br/>" +
                    "<b class='fs-9'>Powodzenia!</b>",
                resources: [
                    {
                        title: "Cotygodniowe spotkania",
                        link: "/produkt/spolecznosc/spotkania"
                    },
                    {
                        title: "Discord",
                        link: "/produkt/spolecznosc"
                    }
                ]
            },
            {
                title: "Dodatkowe narzędzia",
                description: "Dostęp do wszystkich materiałów, o których była mowa przy dołączaniu do kursu, otrzymasz we właściwym tygodniu. <br/> " +
                    "<ul>" +
                    "<li class='mb-1 mt-3'>Dokument \"BEST Self\" - Tydzień 1</li>" +
                    "<li class='mb-1'>Dokument \"Roczny Plan Dominacji\" - Tydzień 2</li>" +
                    "<li class='mb-1'>Autorskie Techniki Oddechowe - Tydzień 2</li>" +
                    "</ul>" +
                    "Temat Sztucznej Inteligencji będzie szczegółowo omawiany w Tygodniu 7, jednak dostęp do Chatu masz już teraz. Więcej w sekcji \"Materiały\". <br/><br/>",
                resources: [
                    {
                        title: "Chat Produkacji",
                        link: "/produkt/chat"
                    },
                ]
            }
        ]
    },
    {
        title: "Budowa świadomości",
        episodes: [
            {
                title: 'Przedstawienie tygodnia',
                vimeoUrl: 'https://vimeo.com/1048286859',
                description: "W tej lekcji dowiesz się, jak wygląda pierwszy tydzień i czego się w nim spodziewać."
            },
            {
                title: 'Po co mi świadomość?',
                vimeoUrl: 'https://vimeo.com/1048286897',
                description: "W tej lekcji poznasz podstawy pracy ze świadomością. Zrozumiesz, czym jest, dlaczego jest istotna oraz jak wykorzystywać ją do swoich celów.<br/>" +
                    "Na świadomości będziemy bazować w całym kursie, dlatego tak kluczowe jest jej zrozumienie. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Dlaczego świadomość jest istotna.</li>" +
                    "<li>Przykład niskiej świadomości.</li>" +
                    "<li>Jak działają najlepsi.</li>" +
                    "</ul>",
            },
            {
                title: 'Idealna wersja siebie',
                vimeoUrl: 'https://vimeo.com/1048286949',
                description: "W tej lekcji przejdziemy przez ćwiczenie polegające na stworzeniu idealnej wersji siebie. Zaczniemy od analizy sytuacji obecnej, a następnie stworzymy personę, którą chcesz się stać i do której dążysz. Na podstawie tego ćwiczenia, będziesz mógł/mogła jasno określić dalsze działania konieczne do stawania się najlepszą wersją siebie",
                resources: [
                    {
                        link: 'https://docs.google.com/document/d/1muaPqx4UlmK9taEOqH4Qxw8pWY-Eo317eyNV_bo-sL8/edit?usp=sharing',
                        title: 'Idealna wersja siebie (Wersja podstawowa)'
                    },
                    {
                        link: 'https://docs.google.com/document/d/1H_ogNwmD6PN27LEvW-4_itdbP339IJJ2iQI8afWsooQ/edit?usp=sharing',
                        title: 'Idealna wersja siebie (Wersja rozszerzona)'
                    }
                ]
            },
            {
                title: 'Rola naszego nastawienia',
                vimeoUrl: 'https://vimeo.com/1048286994',
                description: "W tej lekcji zrozumiesz dokładnie rolę nastawienia i dowiesz się, w jaki sposób Twój mindset kształtuje Twoje życie. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Dlaczego nasze nastawienie jest istotne.</li>" +
                    "<li>Różnice pomiędzy \"Fixed mindset\" i \"Growth mindset\".</li>" +
                    "<li>Jak pracować z nastawieniem.</li>" +
                    "</ul>",
            },
            {
                title: 'Marginalne zyski',
                vimeoUrl: "https://vimeo.com/1048880467",
                description: "W tej lekcji skupimy się na marginalnych zyskach - małych zmianach, które nałożone na siebie, dają w długoterminowej perspektywie ogromne rezultaty. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Przykład marginalnych zysków na podstawie historii Brytyjskich kolarzy.</li>" +
                    "<li>Czym są marginalne zyski.</li>" +
                    "<li>Dlaczego marginalne zyski są istotne.</li>" +
                    "<li>W jaki sposób mierzyć marginalne zyski.</li>" +
                    "</ul>",
                resources: [
                    {
                        title: "Historia Brytyjskich kolarzy (dla zainteresowanych)",
                        link: "https://jamesclear.com/marginal-gains"
                    }
                ]
            },
            {
                title: 'Cykl Kolba',
                description: "W tej lekcji zajmiemy się dokładnym zrozumieniem Cyklu Kolba.<br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Czym jest Cykl Kolba i dlaczego jest on istotny.</li>" +
                    "<li>Jak tworzyć Cykl Kolba.</li>" +
                    "<li>Przykład Cyklu Kolba.</li>" +
                    "</ul>" +
                    "Po przerobieniu tej lekcji, <b>Twoim zadaniem będzie stworzenie przynajmniej dwóch Cyklów Kolba</b>. ",
                vimeoUrl: "https://vimeo.com/1048880606",
                resources: [
                    {
                        title: 'Cykl Kolba - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1bsmkEtrmOgDHhBmo5MYiRo0duydQIP9btpFDDtLJ468/edit?usp=sharing'
                    },
                    {
                        title: 'Przykład cyklu Kolba z lekcji',
                        link: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/Przyk%C5%82ad+Cyklu+Kolba+z+filmu.pdf'
                    },
                    {
                        title: 'Przykłady innych cyklów Kolba (blog)',
                        link: 'https://produkacja.pl/blog/2i5Lz6X464cb7STIj8W75WMKfA7'
                    }
                ]
            },
            {
                title: 'Podsumowanie tygodnia',
                description: "Przed przejściem do kolejnego tygodnia, upewnij się, że: <br/><br/>" +
                    "<ul>" +
                    "<li>Dokument \"BEST Self\" jest przynajmniej w ukończony przynajmniej dla obecnej wersji siebie.</li>" +
                    "<li>Znasz różnice między <i>Fixed</i> i <i>Growth</i> mindset.</li>" +
                    "<li>Zdajesz sobie sprawę, w jaki sposób działać każdego dnia, aby codziennie przybliżać się do najlepszej wersji siebie.</li>" +
                    "<li>Mierzysz marginalne zyski w kontekście swojego celu. </li>" +
                    "<li>Dwa Cykle Kolba zostały w pełni ukończone.</li>" +
                    "</ul>" +
                    "<br/>Jeżeli masz jakieś pytania, śmiało napisz na Discordzie lub weź udział w cotygodniowych spotkaniach na Zoomie. <br/><br/>" +
                    "Jeżeli chcesz się z nami podzielić czymś na temat Kursu, kliknij w przycisk \"Prześlij Feedback, zgłoś błąd\" i napisz nam, co uważasz. Dziękujemy :) <br/><br/>" +
                    "P.S. Kolejny tydzień odblokuje się automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z tego tygodnia. Jeżeli nie jest on jeszcze dla Ciebie dostępny, nie przejmuj się. <br/>" +
                    "Pamiętaj, że <b>praktyka > teoria</b>. Poświęć więcej czasu na przećwiczenie zagadnień, które omówiliśmy sobie w tym tygodniu i podziękuj sobie później!",
            },

        ]
    },
    {
        title: "Podstawy Efektywnego Działania",
        episodes: []
    },
    {
        title: "Wstęp do Efektywnej Nauki",
        episodes: []
    },
    {
        title: "Środowisko",
        episodes: []
    },
    {
        title: "Wystrzał",
        episodes: []
    },
    {
        title: "Optymalizacja",
        episodes: []
    },
    {
        title: "Top 1% uczniów",
        episodes: []
    },
    {
        title: "Boost",
        episodes: []
    },
    {
        title: "Następny poziom",
        episodes: []
    },
] as LearningCourseWeek[]

export interface LearningCourseWeek {
    title: string
    episodes: LearningCourseEpisode[]
}

export interface LearningCourseEpisode {
    title: string
    description: string
    vimeoUrl: string
    resources?: Resource[]
}

export interface Resource {
    title: string
    link: string
}

export interface LastWatchedEpisode {
    w: number,
    e: number,
}

export interface CourseStatistics {
    totalWatchTimeInSeconds: number
    boughtAt: string // Date in ISO
    lastWatched: LastWatchedEpisode
    episodesCompleted: number
    name: string
    email: string
    maxIndividualSessionsNum: number,
    individualSessionsScheduledNum: number
    individualSessionUrl: string,
    weeksUnlocked: number
}

export interface CourseWeek {
    week: number
    isUnlocked: boolean
    episodesCompleted: number
}

export interface CourseEpisode {
    week: number
    episode: number
    isUnlocked: boolean
    watchTimeInSeconds: number
    currentWatchTime: number
    completed: boolean
    notes?: string
}

export interface CourseWeekAndEpisodes {
    week: CourseWeek
    episodes: CourseEpisode[]
    weeksUnlocked: number
}

export interface UpdateCourseWatchTimeRequest {
    week: number
    episode: number
    currentWatchTime: number
    increaseWatchTime: number
}

export interface UpdateEpisodeNotes {
    week: number
    episode: number
    notes: string
}

export interface CompleteEpisodeRequest {
    week: number
    episode: number
    completed: boolean
}