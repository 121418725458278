import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";
import React, {Suspense} from "react";
import LoadingPage from "../../../common/LoadingPage";
import {Await, useLoaderData} from "react-router-dom";
import ErrorContent from "../../../../components/common/error/ErrorContent";
import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import ProdukacjaCopyrightNote from "../../../../components/funnel/ProdukacjaCopyrightNote";
import WebinarKedSuccessfulPurchase
    from "../../../../components/funnel/after-optin/webinar/WebinarKedSuccessfulPurchase";

type LoaderData = {
    webinarDetails: Promise<WebinarDetails>;
};

export default function WebinarKedSuccessfulPurchasePage() {
    const {webinarDetails} = useLoaderData() as LoaderData;

    return <>
        <Meta title="Sukces!"/>
        <MainDesign containerStyles='container py-20' minHeight='100vh'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={webinarDetails} errorElement={<ErrorContent/>}>
                    {(loadedDetails: WebinarDetails) => <>
                        <WebinarKedSuccessfulPurchase webinarDetails={loadedDetails}/>
                        <ProdukacjaCopyrightNote/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}