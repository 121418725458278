import React, {useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {useSubmit} from "react-router-dom";
import Heading from "../../common/header/Heading";
import AdminKedNavbar from "./AdminKedNavbar";
import CustomSelect from "../../ui/form/CustomSelect";


export default function AdminKedOffer() {
    const {isSubmitting} = useSubmitFormAction('/admin/ked', null, async (body) => {
        setRedirectUrl(body)
        await navigator.clipboard.writeText(body);
    }, false, (body, status) => {
        return status === 200 ? "Pomyślnie wygenerowano link i skopiowano do schowka." : `Wystąpił błąd: ${body}`
    });

    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(4997.00);
    const [type, setType] = useState<"KED" | "MW">("MW");
    const [expiresAt, setExpiresAt] = useState("");
    const [benefits, setBenefits] = useState<string[]>([]);
    const [additionalComment, setAdditionalComment] = useState<string>('');

    const submit = useSubmit();

    function generateLink() {
        submit({
            name: name,
            price: price,
            type: type,
            expiresAt: expiresAt,
            benefits: benefits.join('###'),
            additionalComment: additionalComment
        }, {
            method: "POST",
        });
    }

    return <>
        <Heading title='Utwórz indywidualną ofertę' badgeText='oferta'/>
        <AdminKedNavbar/>
        <hr className='text-white'/>
        <div className='mb-12 row g-8 align-items-center'>
            <div className='col-12 col-md-3 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-1'
                    >
                        Imię klienta / klientki
                    </label>
                    <input
                        className='input-lg form-control shadow-sm border-0 bg-dark'
                        id='modalInput2-1'
                        type='text'
                        placeholder="Marysia"
                        aria-describedby='title'
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        style={name ? {color: 'white'} : {}}
                    />
                </div>
            </div>
            <div className='col-12 col-md-3 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-1'
                    >
                        Cena (PLN)
                    </label>
                    <input
                        className='input-lg form-control shadow-sm border-0 bg-dark'
                        id='modalInput2-1'
                        type='number'
                        placeholder="3"
                        aria-describedby='title'
                        value={price}
                        onChange={(event) => setPrice(event.target.value as unknown as number)}
                        style={price ? {color: 'white'} : {}}
                    />
                </div>
            </div>
            <div className='col-12 col-md-3 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-1'
                    >
                        Wybierz rodzaj
                    </label>
                    <CustomSelect value={type} name='type' onChange={(event) => {
                        setType(event.target.value as "KED" | "MW");
                    }}>
                        <option value='MW'>MISTRZOWSKIE Wsparcie</option>
                        <option value='KED'>Kurs Efektywnego Działania</option>
                    </CustomSelect>
                </div>
            </div>
            <div className='col-12 col-md-3 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-1'
                    >
                        Wygasa (opcjonalnie) -&gt; ISO
                    </label>
                    <input
                        className='input-lg form-control shadow-sm border-0 bg-dark'
                        id='modalInput2-1'
                        type='text'
                        placeholder="2024-12-24T18:00"
                        aria-describedby='title'
                        value={expiresAt}
                        onChange={(event) => setExpiresAt(event.target.value)}
                        style={expiresAt ? {color: 'white'} : {}}
                    />
                </div>
            </div>
            <div className='col-12 col-md-7 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-1'
                    >
                        Lista benefitów
                    </label>
                    <textarea
                        className='input-lg form-control shadow bg-dark'
                        id='modalInput2-1'
                        style={benefits ? {color: 'white'} : {}}
                        aria-describedby='benefitsHelp'
                        placeholder='Opisz, co ta osoba otrzymuje...'
                        rows={4}
                        value={benefits.join('\n')}
                        onChange={(event) => setBenefits(event.target.value.split('\n'))}
                    ></textarea>
                </div>
            </div>
            <div className='col-12 col-md-5 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-2'
                    >
                        Dodatkowy komentarz, ustalenia
                    </label>
                    <textarea
                        className='input-lg form-control shadow bg-dark'
                        id='modalInput2-2'
                        style={additionalComment ? {color: 'white'} : {}}
                        aria-describedby='commentHelp'
                        placeholder='Opisz, co zostało ustalone...'
                        rows={4}
                        value={additionalComment}
                        onChange={(event) => setAdditionalComment(event.target.value)}
                    ></textarea>
                </div>
            </div>
            <div className='mt-4'>
                <AnimatedButton
                    className='btn btn-lg btn-success py-3 px-4 h-100 shadow'
                    onClick={() => generateLink()}
                    disabled={isSubmitting}
                    customScale={1}
                >
                    Wygeneruj unikalną ofertę
                </AnimatedButton>
            </div>

            {redirectUrl &&
                <a target='_blank' rel="noopener noreferrer" className='text-success text-decoration-underline'
                   href={redirectUrl}>{redirectUrl}</a>}
        </div>
    </>
}