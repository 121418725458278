import React from "react";

interface ResponsivePhotoWithTextProps {
    photoUrl: string;
    textPart: JSX.Element;
    paddingPhoto: string;
    customWrapperStyles?: string;
    swap?: boolean;
}

export default function ResponsivePhotoWithText(props: ResponsivePhotoWithTextProps) {
    const {photoUrl, textPart, paddingPhoto, customWrapperStyles, swap} = props;

    return (
        <div className={customWrapperStyles ?? "row mt-8 mw-md-7xl mx-auto"}>
            {/* The image column is placed FIRST in the DOM so it appears above on mobile.
          If swap is true, order-md-2 pushes it to the RIGHT for desktops. */}
            <div className={`col-md-6 col-12 text-center${swap ? " order-md-2" : ""}`}>
                <div style={{position: "relative", width: "100%", paddingTop: paddingPhoto}}>
                    <img
                        data-src={photoUrl}
                        className="img-fluid lazyload"
                        alt="zdjęcie obok tekstu"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </div>
            </div>

            {/* The text column is placed SECOND in the DOM so it appears below on mobile.
          If swap is true, order-md-1 pulls it to the LEFT for desktops. */}
            <div className={`col-md-6 col-12${swap ? " order-md-1" : ""}`}>
                <div className="container">
                    {textPart}
                </div>
            </div>
        </div>
    );
}