import Header from "../../ui/header/Header";

export default function ProductsHeader() {
    return <Header
        loaderId='productRoot'
        topItems={[
            {
                title: 'Program',
                to: '/produkt/kurs-efektywnego-dzialania'
            },
            {
                title: 'Chat',
                to: '/produkt/chat'
            },
            {
                title: 'Społeczność',
                to: '/produkt/spolecznosc'
            }
        ]}
        logoCustomRedirect='/produkt'
        dropdownItems={[
            {
                title: 'Lista transakcji',
                to: '/transakcje'
            }
        ]}
    />
}