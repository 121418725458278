import React from 'react'

interface ResponsiveElementProps {
    mobileElement: JSX.Element
    desktopElement: JSX.Element
}

export default function DependentOnScreenSizeElement(props: ResponsiveElementProps) {
    const {mobileElement, desktopElement} = props

    return (
        <>
            <div className='d-none d-md-block '>
                {desktopElement}
            </div>
            <div className='d-md-none'>
                {mobileElement}
            </div>
        </>
    )
}