import {Await, useLoaderData} from "react-router-dom";
import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";
import React, {Suspense} from "react";
import LoadingPage from "../../../common/LoadingPage";
import ErrorContent from "../../../../components/common/error/ErrorContent";
import ProdukacjaCopyrightNote from "../../../../components/funnel/ProdukacjaCopyrightNote";
import WebinarKedPurchase from "../../../../components/funnel/after-optin/webinar/sales-page/WebinarKedPurchase";

type LoaderData = {
    webinarDetails: Promise<WebinarDetails>;
};

export default function WebinarKedPurchasePage() {
    const {webinarDetails} = useLoaderData() as LoaderData;

    return <>
        <Meta title="🔥 83% zniżki na Kurs Efektywnego Działania 🔥"/>
        <MainDesign containerStyles='container py-12 py-md-20' minHeight='100vh'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={webinarDetails} errorElement={<ErrorContent/>}>
                    {(_: WebinarDetails) => <>
                        <WebinarKedPurchase fromWebinar={true}/>
                        <ProdukacjaCopyrightNote/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}
