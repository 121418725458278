import React from "react";
import ResponsivePhotoWithText from "../../../../ui/element/ResponsivePhotoWithText";
import ResponsiveElement from "../../../../ui/element/ResponsiveElement";
import AdvantageItem from "../../../../ui/item/AdvantageItem";
import SalesPageCTA from "./SalesPageCTA";
import PossibleUniversities from "../../../../user/other-logos/PossibleUniversities";
import DependentOnScreenSizeElement from "../../../../ui/element/DependentOnScreenSizeElement";
import SalesPageNumbersItem from "./SalesPageNumbersItem";
import SimpleTestimonial from "./SimpleTestimonial";
import {useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../../../hooks/useSubmitFormAction";

interface WebinarKedPurchaseProps {
    fromWebinar: boolean
}

export default function WebinarKedPurchase({fromWebinar}: WebinarKedPurchaseProps) {
    const {isSubmitting} = useSubmitFormAction(
        "whatever",
        null,
        (body) => {
            window.open(body, "_blank");
        },
        false,
        undefined,
        undefined,
        true
    );

    const submit = useSubmit();

    function submitForm() {
        submit({}, {
            method: "POST"
        });
    }

    return (
        <>
            <div className="mw-md-6xl mx-auto text-center text-white">
                <DependentOnScreenSizeElement mobileElement={<>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-3 text-white'>Pomóż swojemu
                        dziecku zostać</h2>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-2 text-white'><span
                        className='text-success'>NAJLEPSZYM </span>
                        uczniem.</h2>
                    <p className='text-warning fw-medium mw-md-3xl mx-auto fs-10 mt-3'>Nawet, jeżeli nie wie jak się
                        uczyć,
                        nie ma motywacji do działania, nie uczy się
                        systematycznie,
                        nie wierzy w siebie lub jest uzależnione od telefonu/komputera.</p>
                </>} desktopElement={<>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-5 text-white'>Pomóż <u>swojemu
                        dziecku</u> zostać</h2>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-4 text-white'><span
                        className='text-success'>NAJLEPSZYM </span>
                        uczniem.</h2>
                    <p className='text-warning fw-medium mw-md-3xl mx-auto fs-10 mt-3'>Nawet, jeżeli nie wie jak się
                        uczyć,
                        nie ma motywacji do działania, nie uczy się
                        systematycznie,
                        nie wierzy w siebie lub jest uzależnione od telefonu/komputera.</p>
                </>}/>
                <div className='mt-12'/>
                <SalesPageCTA minimal isSubmitting={isSubmitting} submit={submitForm} fromWebinar={fromWebinar}/>
                <PossibleUniversities/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-devices.png'
                    paddingPhoto='74.89%'
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Czym jest <u>Kurs Efektywnego Działania</u>? <img
                                    style={{height: '32px'}}
                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Kurs Efektywnego Działania to <b>jedyny w Polsce</b>,
                                    dostosowany <b>specjalnie pod nastolatków</b> program,
                                    który całkowicie zmieni ich podejście do edukacji i rozwoju. </p>
                                <p className='fw-medium fs-10'>Jest to 9-tygodniowy program łączący teorię z dużą
                                    ilością praktyki, po którym KAŻDY uczeń
                                    nauczy się działać efektywniej i osiągać więcej.</p>
                                <p className='fw-medium fs-10'>Kurs jest dostępny na całe życie, z każdego miejsca na
                                    świecie i o każdej porze.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-10'/>

                <ResponsivePhotoWithText
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-webinar-oferta.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    textPart={<>
                        <ResponsiveElement mobileClasses='fs-5 text-start mt-8 mt-md-0'
                                           desktopClasses='fs-7 text-start' element={
                            <h3>Czego dowie się Twoje dziecko?
                            </h3>
                        }/>
                        <div className='text-start'>
                            <AdvantageItem
                                title='Jak uczyć się efektywnie i dostawać najlepsze oceny przy minimalnym nakładzie pracy. '/>
                            <AdvantageItem
                                title='Jak RAZ NA ZAWSZE wygrać z prokrastynacją i zacząć działać SYSTEMATYCZNIE.'/>
                            <AdvantageItem
                                title='Jak uniezależnić się od telefonu i komputera.'/>
                            <AdvantageItem
                                title='Jak wykształcić w sobie zwycięskie nastawienie i chętnie wychodzić ze strefy komfortu.'/>
                            <AdvantageItem
                                title='Jak lepiej poznać siebie, jasno określić swój cel i stworzyć konkretny PLAN DZIAŁANIA.'/>
                            <AdvantageItem
                                title='I wiele, wiele więcej...'/>
                        </div>
                    </>}/>

                <div className='mt-12'/>
                <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={fromWebinar}/>

                <h3 className='mt-16 fs-5 text-success'>30-dniowa Gwarancja Satysfakcji <img
                    style={{height: '68px'}}
                    alt='jakość'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                </h3>
                <DependentOnScreenSizeElement mobileElement={<img style={{height: '192px'}}
                                                                  src="https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/gwarancja.png"
                                                                  alt='gwarancja'/>}
                                              desktopElement={<img style={{height: '256px'}}
                                                                   src="https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/gwarancja.png"
                                                                   alt='gwarancja'/>}/>
                <p className='fw-medium mx-auto mw-md-2xl fs-9 mt-6'>Jeżeli Twoje dziecko przejdzie przez program i
                    zacznie wprowadzać w życie zmiany, <b>zobaczy rezultaty</b>. Kropka.</p>

                <p className='fw-medium mx-auto mw-md-2xl fs-10 mt-6'>Jednak jeżeli nie do końca w to wierzysz,
                    proponuję Ci <b>30-dniową gwarancję satysfakcji</b>, więc Twoje dziecko będzie mogło przejść
                    przez nasz program bez żadnego ryzyka.</p>

                <p className='fw-medium mx-auto mw-md-2xl fs-9 mt-6'>Zapisz swoje dziecko już teraz, a jeżeli
                    po obejrzeniu dwóch pierwszych tygodni kursu i
                    wdrożeniu w życie zmian nie będzie zadowolone
                    z rezultatów, <u> zwrócimy Ci 100% zainwestowanej kwoty</u>.</p>

                <div className='mt-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/community.png'
                    paddingPhoto='56.25%'
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Dożywotni dostęp do naszej
                                    prywatnej społeczności
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'><i>Jesteś sumą 5 osób, z którymi spędzasz najwięcej
                                    czasu.</i></p>
                                <p className='fw-medium fs-10'>Pomyśl jak zmieni się życie Twojego dziecka, jeżeli
                                    będzie otaczać się nastawionymi na rozwój, ambitnymi i pomocnymi rówieśnikami.</p>
                                <p className='fw-medium fs-10'><b>Odpowiedź jest prosta</b>: Stanie się takie jak oni.
                                    Ambitne,
                                    nastawione na rozwój, chętnie wychodzące ze swojej strefy komfortu i skutecznie
                                    realizujące swoje cele.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/zoom-meetings.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Dożywotni dostęp do regularnych
                                    spotkań online z twórcą kursu
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Dzięki cotygodniowym spotkaniom na żywo możemy
                                    pomóc każdej osobie w czasie rzeczywistym i upewnić się,
                                    że każdy odniesie sukces.</p>
                                <p className='fw-medium fs-10'>Godzina konsultacji z Mikołajem kosztuje <b>1000PLN+</b>,
                                    więc otrzymujecie niesamowity bonus - i to na całe życie.</p>
                            </div>

                        </>
                    }/>

                <hr className='text-white mt-8 mt-md-16'/>
                <div className='row'>
                    <h3 className='fs-5 mb-md-8'>Spójrzmy na liczby...
                    </h3>
                    <SalesPageNumbersItem metricTitle='Lata doświadczenia' metricValue={5}/>
                    <SalesPageNumbersItem metricTitle='Zadowoleni uczniowie' metricValue={'3.000+'}/>
                    <SalesPageNumbersItem metricTitle='Zadowoleni rodzice' metricValue={'98.3%'}/>

                </div>
                <hr className='text-white mb-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/best-self.png'
                    paddingPhoto='56.25%'
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorski dokument "BEST Self"
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Ten dokument pomoże Twojemu dziecku stworzyć jasny plan
                                    działania do realizacji swoich celów.</p>
                                <p className='fw-medium fs-10'>Specjalnie stworzone pytania skłonią je do myślenia i po
                                    pewnym czasie, odpowiedzi przyjdą zupełnie naturalnie.</p>
                                <p className='fw-medium fs-10'>Dostępny w dwóch wersjach - podstawowa (26 pytań) i
                                    rozbudowana (+-100 pytań). </p>
                            </div>

                        </>
                    }/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/plan-dominacji.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorski dokument "Roczny Plan Dominacji"
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Jest to specjalny planer do wygrania 2025 roku.</p>
                                <p className='fw-medium fs-10'>Jego przejrzystość i prostota sprawiają, że nawet
                                    najbardziej oporni uczniowie chętnie zaczynają z niego korzystać.</p>
                                <p className='fw-medium fs-10'>Koniec nauki na ostatnią chwilę, zapominania o
                                    sprawdzianach, kartkówkach czy urodzinach swoich rodziców.</p>
                            </div>

                        </>
                    }/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/ai-nauka.png'
                    paddingPhoto='56.25%'
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Sztuczna Inteligencja dostosowana pod Efektywną Naukę</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Zintegrowaliśmy najnowsze rozwiązania AI z naszą
                                    platformą online.</p>
                                <p className='fw-medium fs-10'>Od teraz, zamiast bezmyślnie przepisywać odpowiedzi
                                    czy zadania domowe, uczniowie będą musieli samodzielnie dojść do rozwiązania.</p>
                                <p className='fw-medium fs-10'>AI będzie ich wspierało i zadawało pytania skłaniające do
                                    myślenia - aby pobudzać mózg do jak najlepszego przetwarzania informacji.</p>
                            </div>

                        </>
                    }/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/techniki-oddechowe.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorskie techniki oddechowe</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>W ostatnich latach młodzież coraz gorzej radzi sobie ze
                                    stresem.</p>
                                <p className='fw-medium fs-10'>Niepokój przed sprawdzianami czy kartkówkami skutecznie
                                    obniża ich koncentrację i odbiera szansę na dobrą ocenę.</p>
                                <p className='fw-medium fs-10'>Dzięki specjalnie przygotowanym technikom oddechowym,
                                    stres zniknie w mgnieniu oka.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-16'/>

                <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={fromWebinar}/>

                <div className='mt-16'/>
                <SimpleTestimonial name='Bohdi Sanders' quote='Brak podjęcia decyzji to również podjęcie decyzji.'
                                   description='Znany autor, filozof, trener życia i ekspert w dziedzinie filozofii sztuk walki.'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/bohdi-sanders.png'/>
            </div>
        </>
    );
}