import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import RedirectAfterRegistration from "../../../components/user/thank-you/RedirectAfterRegistration";

export default function RedirectAfterRegistrationPage() {

    return (
        <>
            <Meta title='Dziekujemy za rejestrację!'/>
            <MainDesign containerStyles='container py-28'>
                <RedirectAfterRegistration/>
            </MainDesign>
        </>
    );
}