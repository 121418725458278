import {Form, Link} from "react-router-dom";
import React from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import AnimatedButton from "../ui/motion/AnimatedButton";

export default function ActivateAccountForm() {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const {isSubmitting} = useSubmitFormAction('/produkt', null, undefined, true, undefined, (body) => {
        setErrorMessage(body);
    }, true);

    return <Form method='post' className='mw-sm mx-auto'>
        <div className={`${errorMessage ? 'mb-0' : 'mb-6'} row g-3`}>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-2'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Wprowadź hasło'
                        name='password'
                        required
                        autoComplete='new-password'
                    />
                </div>
            </div>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Powtórz hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-3'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Powtórz hasło'
                        name='confirmPassword'
                        required
                        autoComplete='new-password'
                    />
                </div>
            </div>
            {
                errorMessage &&
                <p className='text-danger fs-12 mt-2' role='alert'>
                    {errorMessage}
                </p>
            }
        </div>
        <div className='mb-2 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    type='submit'
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Trwa tworzenie konta" : "Utwórz konto"}
                </AnimatedButton>
            </div>
        </div>
        <p className='mb-1 fs-14 fw-medium text-white text-left mt-2'>
            <span>Rejestrując się akceptujesz </span>
            <Link className='text-success link-success' to='/polityka-prywatnosci'>
                politykę prywatności
            </Link>
            <span> oraz </span>
            <Link className='text-success link-success' to='/regulamin'>
                regulamin
            </Link>
            <span>.</span>
        </p>
    </Form>
}