import React, {useRef} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {useBubbleNotification} from "../../../hooks/useBubbleNotification";

interface BubbleNotificationProps {
    message: string;
    delay?: number;
    duration?: number;
}

// You can add, remove, or change these as you like
const names = [
    "Mirosław",
    "Agnieszka",
    "Monika",
    "Marta",
    "Adam",
    "Andrzej",
    "Julia",
    "Anna",
];
const times = [5, 6, 7, 8, 9, 10, 11];

function getRandomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

export function BubbleNotificationWithPlaceholders({
                                                       message,
                                                       delay,
                                                       duration,
                                                   }: BubbleNotificationProps) {
    const {showBubble, setShowBubble} = useBubbleNotification({
        delay,
        duration,
    });

    /**
     * Store the replaced message in a ref so that it doesn't get recalculated
     * on every re-render. This ensures the bubble text remains the same
     * until it's hidden or unmounted.
     */
    const replacedMessageRef = useRef<string>("");

    // Only calculate the message once:
    if (!replacedMessageRef.current) {
        const randomName = getRandomElement(names);
        const randomTime = getRandomElement(times);

        replacedMessageRef.current = message
            .replace("{name}", randomName)
            .replace("{time}", String(randomTime));
    }

    return (
        <AnimatePresence>
            {showBubble && (
                <motion.div
                    className="position-fixed bottom-0 start-0 mb-3 ms-3 p-2 bg-dark text-white rounded-pill shadow"
                    style={{zIndex: 1000}}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 50}}
                    transition={{duration: 0.5}}
                    onClick={() => setShowBubble(false)}
                >
                    {replacedMessageRef.current}
                </motion.div>
            )}
        </AnimatePresence>
    );
}