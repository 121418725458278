import ChatPricing from "../../../../components/user/ai/pricing/chat/ChatPricing";
import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";

export default function ChatPurchaseTokensInProducts() {
    return <>
        <Meta title='Kup tokeny'/>
        <MainDesign containerStyles='py-28 container'>
            <ChatPricing/>
        </MainDesign>
    </>
}