import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React from "react";
import LearningCourseEnroll from "../../../components/user/learning-course/LearningCourseEnroll";
import {PurchaseService} from "../../../service/PurchaseService";
import {PurchaseOption} from "../../../model/user/purchase/PurchaseOption";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {redirect} from "react-router-dom";
import {ApiError} from "../../../service/HttpService";

export default function LearningCourseEnrollPage() {
    return <>
        <Meta title="Kurs Efektywnego Działania"
              description='Działaj efektywniej i osiągaj więcej! Kurs efektywnej nauki połączony z elementami efektywnego działania.'/>
        <MainDesign containerStyles='container py-16 py-md-28'>
            <LearningCourseEnroll/>
        </MainDesign>
    </>
}

export async function action({request, params}: { request: Request, params: any }) {
    const formData = await request.formData();
    const purchaseData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };

    try {
        const redirectUrl = await PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_BASIC_ACCESS.toString(),
            name: purchaseData.name,
            email: purchaseData.email,
            source: localStorage.getItem('firstJoinedFrom') ?? '',
            successRedirectPath: "/zakup/kurs-efektywnego-dzialania/sukces",
            errorRedirectPath: "/zakup/kurs-efektywnego-dzialania/blad",
        });
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${redirectUrl}, purchaseOptionName: ${PurchaseOption.COURSE_KED_BASIC_ACCESS.toString()}`);
        return redirect(redirectUrl);
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}