import Heading from "../../../../common/header/Heading";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";
import SlideInWhenVisible from "../../../../ui/motion/SlideInWhenVisible";
import {TraceService} from "../../../../../service/TraceService";
import {TraceType} from "../../../../../model/user/general/Trace";

interface ChatHeaderProps {
    isLoggedIn: boolean
}

export default function ChatHeader(props: ChatHeaderProps) {
    const {isLoggedIn} = props
    return <>
        <div className="container">
            <div className="pt-4 pb-10 position-relative" style={{zIndex: 1}}>
                <div className="mb-16 text-center mw-md-3xl mx-auto">
                    <Heading badgeText='Oferta chat' title='Prywatny nauczyciel 24/7?!'
                             subtitle='Czy kiedykolwiek chciałeś/aś mieć swojego prywatnego nauczyciela, dostępnego 24/7,
                             który nie tylko zna odpowiedzi na wszystkie Twoje pytania, ale również wspiera Cię na
                             każdym etapie nauki?'/>

                    <div className="row justify-content-center g-4 pt-2">
                        <div className="col-12 col-md-auto">
                            <SlideInWhenVisible initialX={-100} duration={0.4}>
                                <AnimatedLink
                                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                                    onClick={() => {
                                        TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> wypróbuj teraz')
                                    }}
                                    to={isLoggedIn ? '/chat' : '/rejestracja'}>Wypróbuj teraz</AnimatedLink>
                            </SlideInWhenVisible>

                        </div>
                        <div className="col-12 col-md-auto">
                            <SlideInWhenVisible initialX={100} duration={0.4} delay={0.4}>
                                <AnimatedLink hashLink={true}
                                              onClick={() => {
                                                  TraceService.addTrace(TraceType.BtnClick, 'btn-2 -> czytaj dalej')
                                              }}
                                              className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                              to="#potrzeba">Czytaj dalej</AnimatedLink>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}