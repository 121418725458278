import {Form} from "react-router-dom";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import AnimatedButton from "../ui/motion/AnimatedButton";
import React, {useState} from "react";

export default function GrantKedAccessToStudentForm() {
    const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const {isSubmitting} = useSubmitFormAction('/rejestracja-udana', null, (body) => {
        setErrorMessage(null)
        setSuccessMessage(body);
    }, false, undefined, (body) => {
        setSuccessMessage(null);
        setErrorMessage(body);
    }, true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isInputValid = isEmailValid && name.length >= 2;

    return <Form method="POST" className='mw-sm mx-auto p-1'>
        <div className='mb-4 row g-6'>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signUpInput3-1'
                    >
                        Imię
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signUpInput2-1'
                        type='text'
                        aria-describedby='nameHelp'
                        placeholder='Wprowadź swoje imię'
                        name='name'
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>
            </div>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signUpInput3-2'
                    >
                        Email
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signUpInput2-2'
                        type='email'
                        aria-describedby='emailHelp'
                        placeholder='Podaj adres email'
                        name='email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
            </div>
            {
                errorMessage &&
                <p className='text-danger fs-12 mt-1 mb-0' role='alert'>
                    {errorMessage}
                </p>
            }
            {
                successMessage &&
                <p className='text-success fs-12 mt-1 mb-0' role='alert'>
                    {successMessage}
                </p>
            }
        </div>
        <div className='mb-3 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    type='submit'
                    disabled={isSubmitting || !isInputValid}
                >
                    {isSubmitting ? "Trwa aktywacja" : "Aktywuj"}
                </AnimatedButton>
            </div>
        </div>
    </Form>
}