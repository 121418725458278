import {PurchaseService} from "../../../../service/PurchaseService";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import {PurchaseOption} from "../../../../model/user/purchase/PurchaseOption";
import {ApiError} from "../../../../service/HttpService";
import {KED_WEBINAR_EXPERIMENT_1} from "../../../../util/ExperimentUtil";
import {DiagnosticService} from "../../../../service/DiagnosticService";

export async function purchaseWebinarAction({request, params}: { request: Request, params: any }) {
    const {ksuid} = params;

    try {
        // Extract the URL from the request
        const url = new URL(request.url);

        // Check if the current path contains '/zakup'
        if (url.pathname.includes('/zakup')) {
            try {
                const extracted = url.pathname.split("/zakup/")[1];
                TraceService.addTraceWithExperiment(TraceType.InitTransactionSalesPage, KED_WEBINAR_EXPERIMENT_1, undefined, extracted);
            } catch (error) {
                DiagnosticService.addDiagnostic({
                    fullEvent: {},
                    error: error,
                    functionName: "purchaseWebinarAction",
                    additionalInfo: "error while extracting path from url"
                })
            }
        }

        const redirectUrl = await PurchaseService.initWebinarPurchase(ksuid);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${redirectUrl}, purchaseOptionName: ${PurchaseOption.COURSE_KED_WEBINAR.toString()}`);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}