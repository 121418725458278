import {
    getSourceAndVariationFromRequest,
    KED_WEBINAR_EXPERIMENT_1,
    MW_FULL_FUNNEL_EXPERIMENT_2
} from "../../../util/ExperimentUtil";
import {NewsletterService} from "../../../service/NewsletterService";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import ReactPixel from "react-facebook-pixel";
import {DiagnosticService} from "../../../service/DiagnosticService";

export async function funnelMWParentOptInAction({request}: { request: Request }) {
    return action(request, MW_FULL_FUNNEL_EXPERIMENT_2, NewsletterType.MWOptInParent)
}

export async function funnelKedWebinarOptInAction({request}: { request: Request }) {
    return action(request, KED_WEBINAR_EXPERIMENT_1, NewsletterType.WebinarKedOptInV1)
}

// TODO: Hardcoded variation A.
export async function action(request: Request, experimentId: string, newsletterType: string) {
    const {
        source,
        variation
    } = await getSourceAndVariationFromRequest(request, experimentId);
    const formData = await request.formData();
    const optInData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };

    try {
        //@ts-ignore
        gtag('event', 'conversion', {
            'send_to': 'AW-16562613681/Q0ESCOTA_MgZELHb1dk9',
            'value': 20.0,
            'currency': 'PLN'
        });

    } catch (error) {
        await DiagnosticService.addDiagnostic({
            fullEvent: request,
            error: error,
            functionName: "mw-opt-in",
            additionalInfo: "error while sending google analytics conversion"
        })
    }

    try {
        ReactPixel.track('SubmitApplication');
    } catch (error) {
        await DiagnosticService.addDiagnostic({
            fullEvent: request,
            error: error,
            functionName: "mw-opt-in",
            additionalInfo: "error while sending facebook's conversion"
        })
    }

    try {
        TraceService.addTraceWithExperiment(TraceType.RequestSend, experimentId, variation, source);

        const {webinarId} = await NewsletterService.subscribeNewsletter({
            name: optInData.name,
            type: newsletterType,
            email: optInData.email,
            source: source,
            variation: variation,
            eventUrl: window.location.href,
            webinarDate: formData.get('webinarDate') as string ?? undefined
        });

        if (webinarId) {
            return {
                status: 200,
                body: webinarId
            }
        } else {
            return {
                status: 400,
                body: "Wystąpił błąd. Spróbuj ponownie później."
            }
        }
    } catch (error) {
        try {
            await DiagnosticService.addDiagnostic({
                fullEvent: request,
                error: error,
                functionName: "mw-opt-in",
                additionalInfo: "error while adding to newsletter"
            })
        } catch (error) {

        }

        return {
            status: 400,
            body: "Wystąpił błąd. Spróbuj ponownie później."
        }
    }
}