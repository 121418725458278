import {motion} from "framer-motion";
import {offerVariants} from "../../../pages/user/offer/OfferLandingPage";
import ProductPageOption from "../ai/pricing/ProductPageOption";

interface ProductsSelectOptionProps {
    isEffectiveActionCourseSubscriber: boolean
}

export default function ProductsSelectOption(props: ProductsSelectOptionProps) {
    const {isEffectiveActionCourseSubscriber} = props;
    return <>
        <div className='row mw-5xl mx-auto'>
            <motion.div
                custom={0} // Custom prop for delay calculation
                variants={offerVariants}
                initial="hidden"
                animate="visible"
                className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
            >
                <ProductPageOption
                    imageUrl={isEffectiveActionCourseSubscriber ?
                        'https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked.png' :
                        'https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-lock.png'}
                    redirectTo={isEffectiveActionCourseSubscriber ? 'kurs-efektywnego-dzialania' : '/oferta/kurs-efektywnego-dzialania'}
                    title='Kurs Efektywnego Działania'
                    ctaCustomText={isEffectiveActionCourseSubscriber ? 'Przejdź do kursu' : 'Uzyskaj dostęp'}
                    subtitle='Działaj Efektywniej i Osiągaj Więcej!'/>
            </motion.div>
            <motion.div
                custom={1} // Increasing the custom value for sequential delay
                variants={offerVariants}
                initial="hidden"
                animate="visible"
                className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
            >
                <ProductPageOption
                    imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/rozwiazania-ai.png'
                    ctaCustomText='Przejdź do chatu'
                    redirectTo='/produkt/chat' title='Chat'
                    subtitle='Zdobywaj wiedzę szybko, wygodnie i łatwo. Nasze modele AI wspierają Cię na każdym etapie nauki, zapewniając niezbędną wiedzę oraz feedback.'/>
            </motion.div>
        </div>
    </>
}