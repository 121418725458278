import {PurchaseRequest} from "../model/user/purchase/PurchaseRequest";
import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    CreateIndividualKedOfferRequest,
    CreateIndividualKedOfferResponse,
    IndividualKedOffer
} from "../model/user/purchase/IndividualKedOffer";
import {
    GrantKedAccessAfterWebinarRequest,
    GrantKedAccessAfterWebinarResponse,
    KedActivationCode
} from "../model/user/purchase/KedAccessAfterWebinar";
import {PurchaseOption} from "../model/user/purchase/PurchaseOption";

export class PurchaseService {

    public static initWebinarPurchase(webinarId: string): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_WEBINAR.toString(),
            name: "Webinar",
            email: "webinar@produkacja.pl",
            source: localStorage.getItem('firstJoinedFrom') ?? 'webinar',
            webinarId: webinarId,
            successRedirectPath: `/webinar/zakup/sukces/${webinarId}`,
            errorRedirectPath: `/webinar/zakup/blad/${webinarId}`,
        });
    }

    public static initPurchase(purchaseRequest: PurchaseRequest): Promise<string> {
        return HttpService.sendRequest<string>(
            HttpMethod.POST,
            `/api/purchase/init`,
            JSON.stringify(purchaseRequest),
            ContentType.JSON
        );
    }

    public static getIndividualKedOffer(id: string): Promise<IndividualKedOffer> {
        return HttpService.sendRequest<IndividualKedOffer>(
            HttpMethod.GET,
            `/api/individual-ked-offer/${id}`,
        );
    }

    public static createIndividualKedOffer(createOfferRequest: CreateIndividualKedOfferRequest): Promise<CreateIndividualKedOfferResponse> {
        return HttpService.sendRequest<CreateIndividualKedOfferResponse>(
            HttpMethod.POST,
            `/api/individual-ked-offer`,
            JSON.stringify(createOfferRequest),
            ContentType.JSON
        );
    }

    public static grantKedAccessAfterWebinar(grantKedAccess: GrantKedAccessAfterWebinarRequest): Promise<GrantKedAccessAfterWebinarResponse> {
        return HttpService.sendRequest<GrantKedAccessAfterWebinarResponse>(
            HttpMethod.POST,
            `/api/activate-ked-access`,
            JSON.stringify(grantKedAccess),
            ContentType.JSON
        );
    }

    public static getKedActivationCode(id: string): Promise<KedActivationCode> {
        return HttpService.sendRequest<KedActivationCode>(
            HttpMethod.GET,
            `/api/ked-activation-code/${id}`,
        );
    }
}