import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import {WebinarService} from "../../../../service/WebinarService";
import {defer, Params} from "react-router-dom";

async function loadWebinarDetails(ksuid: string): Promise<WebinarDetails> {
    return await WebinarService.getWebinarDetails(ksuid);
}

export async function webinarDetailsLoader({params}: { params: Params<"ksuid"> }) {
    return defer({
        webinarDetails: params && params.ksuid ? loadWebinarDetails(params.ksuid!!) : null,
    });
}