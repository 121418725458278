import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Heading from "../../../common/header/Heading";
import React from "react";
import {useSearchParams} from "react-router-dom";

interface WebinarProps {
    webinarDetails: WebinarDetails;
}

export default function WebinarKedSuccessfulPurchase({webinarDetails}: WebinarProps) {

    const [searchParams] = useSearchParams();
    const name = searchParams.get('name');
    const email = searchParams.get('email');

    return (
        <>
            <Heading
                customStyles='mb-6 text-center mw-md-2xl mx-auto'
                title="Zakup udany!"
                badgeText="Sukces"
            />

            <div className='mw-md-2xl mx-auto text-center text-white'>
                <h2 className='fs-7 text-success'>
                    Co dalej?
                </h2>
                <p className='fw-medium mb-2'>
                    Wszystkie instrukcje zostały wysłane
                    na {email ? (
                    <>
                        adres email <i>{email}</i>
                    </>
                ) : (
                    "maila podanego przy płatności."
                )}

                </p>
                <p className='fw-medium mb-4'>
                    Twoje dziecko będzie miało dostęp do kursu od razu po zalogowaniu się.
                </p>
                <p className='fw-medium mb-8'>
                    Jeżeli nie widzisz maila, sprawdź folder spam.
                </p>


                <h2 className='fs-8 mt-8 text-warning'>
                    Pytania / problemy?
                </h2>
                <p className='fw-medium mb-16'>
                    Napisz do nas na adres <a
                    href="mailto:kontakt@produkacja.pl"
                    className="text-success link-success"
                >
                    kontakt@produkacja.pl
                </a>
                </p>
            </div>
        </>
    );
}