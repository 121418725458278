import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Heading from "../../../common/header/Heading";
import React from "react";
import {formatDateWithOWord} from "../../../../util/DateUtil";
import WebinarTestimonials from "./WebinarTestimonials";
// If you're using something like React Router, you might use Link or NavLink.
// For an "AnimatedLink", replace with your actual animated link component.
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";

interface WebinarProps {
    webinarDetails: WebinarDetails;
}

export default function WebinarOptInConfirmation({webinarDetails}: WebinarProps) {
    // Convert webinarDetails.plannedDate into a Date object:
    const webinarDate = new Date(webinarDetails.plannedDate);
    const now = new Date();

    // Calculate the difference in milliseconds:
    const timeDiffMs = webinarDate.getTime() - now.getTime();
    // Convert to minutes:
    const timeDiffMins = timeDiffMs / (1000 * 60);

    // Check if the webinar is starting soon or already started:
    const isStartingSoon = timeDiffMins <= 15 && timeDiffMins > -90;

    return (
        <>
            <Heading
                customStyles='mb-6 text-center mw-md-2xl mx-auto'
                title="Potwierdzamy zapisanie się na webinar."
                badgeText="Sukces"
            />

            <div className='mw-md-2xl mx-auto text-center text-white'>
                <h2 className='fs-8'>
                    Odbędzie się on <span className='text-success'>
                        {formatDateWithOWord(webinarDetails.plannedDate)}
                    </span>.
                </h2>
                <p className={`fw-medium ${isStartingSoon ? 'mb-8' : 'mb-24'}`}>
                    Szczegóły wysłaliśmy Ci na adres email <i>{webinarDetails.email}</i> <br/>
                    Jeżeli nie widzisz maila, sprawdź folder spam.
                </p>

                {/* Conditionally render either the "Join Now" link or the testimonials */}
                {isStartingSoon ? (
                    <AnimatedLink
                        className="btn btn-lg btn-success text-success-light shadow mb-8"
                        onClick={() => {
                            TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> Zacznij działać')
                        }}
                        to={`/webinar/${webinarDetails.id}`}>
                        Dołącz do webinaru
                    </AnimatedLink>
                ) : (
                    <WebinarTestimonials/>
                )}
            </div>
        </>
    );
}