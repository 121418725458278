import React from 'react';
import NewsletterForm, {NewsletterType} from "../user/newsletter/NewsletterForm";
import MainDesign from "../../pages/MainDesign";
import AnimatedLink from "../ui/motion/AnimatedLink";
import {motion} from 'framer-motion';
import {Link} from "react-router-dom";

export default function Footer() {
    return <MainDesign minHeight="300px" containerStyles="container pt-20" removeSectionOverflowHidden={true}
                       hideBackgroundImage={true} minHeightChildrenDiv='300px' disableAnimation={true}>
        <div className='row g-16 pb-12'>
            <div className='col-12 col-md-6 col-lg-4'>
                <div className='mw-md-xs'>
                    <div className='d-flex mb-1'>
                        <Link to='/'>
                            <motion.img
                                className='img-fluid lazyload'
                                loading='lazy'
                                data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/logo/logo-1200-400-horizontal-green.png'
                                style={{height: "100px", width: "300px"}}
                                alt='Poziome logo Produkacja'
                                whileHover={{scale: 1.1}}
                            />
                        </Link>

                    </div>
                    <p className='fs-10 fw-medium text-secondary-light'>
                        Działaj efektywniej i osiągaj więcej.
                    </p>
                    <div className='row'>
                        <div className='col-auto'>
                            <AnimatedLink className='text-success link-success d-inline-block'
                                          customScale={1.3}
                                          to='https://www.facebook.com/profile.php?id=61556407554059'>
                                <svg
                                    aria-label='Ikona facebooka'
                                    role="img"
                                    style={{width: "36px", height: "36px"}}
                                    width={36}
                                    height={36}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M13.6348 20.7272V12.766H16.3582L16.7668 9.66243H13.6348V7.68126C13.6348 6.78299 13.8881 6.17083 15.2029 6.17083L16.877 6.17015V3.39421C16.5875 3.35731 15.5937 3.27271 14.437 3.27271C12.0216 3.27271 10.368 4.71878 10.368 7.37389V9.66243H7.63635V12.766H10.368V20.7272H13.6348Z'
                                        fill='currentColor'
                                    />
                                    <mask
                                        id='mask0_1201_15606'
                                        style={{maskType: 'alpha'}}
                                        maskUnits='userSpaceOnUse'
                                        x={7}
                                        y={3}
                                        width={10}
                                        height={18}
                                    >
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M13.6348 20.7272V12.766H16.3582L16.7668 9.66243H13.6348V7.68126C13.6348 6.78299 13.8881 6.17083 15.2029 6.17083L16.877 6.17015V3.39421C16.5875 3.35731 15.5937 3.27271 14.437 3.27271C12.0216 3.27271 10.368 4.71878 10.368 7.37389V9.66243H7.63635V12.766H10.368V20.7272H13.6348Z'
                                            fill='white'
                                        />
                                    </mask>
                                    <g mask='url(#mask0_1201_15606)'/>
                                </svg>
                            </AnimatedLink>
                        </div>
                        <div className='col-auto'>
                            <AnimatedLink className='text-success link-success d-inline-block'
                                          customScale={1.3}
                                          to='https://www.instagram.com/produkacja.pl/'>
                                <svg
                                    aria-label='Ikona Instagrama'
                                    role="img"
                                    style={{width: "36px", height: "36px"}}
                                    width={36}
                                    height={36}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M7.60057 2.18176H16.3991C19.3872 2.18176 21.8182 4.61276 21.8181 7.60069V16.3992C21.8181 19.3871 19.3872 21.8181 16.3991 21.8181H7.60057C4.61264 21.8181 2.18176 19.3872 2.18176 16.3992V7.60069C2.18176 4.61276 4.61264 2.18176 7.60057 2.18176ZM16.3992 20.0759C18.4266 20.0759 20.076 18.4266 20.076 16.3992H20.0759V7.60069C20.0759 5.57343 18.4265 3.924 16.3991 3.924H7.60057C5.57331 3.924 3.924 5.57343 3.924 7.60069V16.3992C3.924 18.4266 5.57331 20.076 7.60057 20.0759H16.3992ZM6.85709 12.0001C6.85709 9.16418 9.16413 6.85709 11.9999 6.85709C14.8358 6.85709 17.1428 9.16418 17.1428 12.0001C17.1428 14.8358 14.8358 17.1428 11.9999 17.1428C9.16413 17.1428 6.85709 14.8358 6.85709 12.0001ZM8.62792 11.9999C8.62792 13.8592 10.1407 15.3718 11.9999 15.3718C13.8592 15.3718 15.372 13.8592 15.372 11.9999C15.372 10.1405 13.8593 8.62784 11.9999 8.62784C10.1406 8.62784 8.62792 10.1405 8.62792 11.9999Z'
                                        fill='currentColor'
                                    />
                                    <mask
                                        id='mask0_1201_15608'
                                        style={{maskType: 'alpha'}}
                                        maskUnits='userSpaceOnUse'
                                        x={2}
                                        y={2}
                                        width={20}
                                        height={20}
                                    >
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M7.60057 2.18176H16.3991C19.3872 2.18176 21.8182 4.61276 21.8181 7.60069V16.3992C21.8181 19.3871 19.3872 21.8181 16.3991 21.8181H7.60057C4.61264 21.8181 2.18176 19.3872 2.18176 16.3992V7.60069C2.18176 4.61276 4.61264 2.18176 7.60057 2.18176ZM16.3992 20.0759C18.4266 20.0759 20.076 18.4266 20.076 16.3992H20.0759V7.60069C20.0759 5.57343 18.4265 3.924 16.3991 3.924H7.60057C5.57331 3.924 3.924 5.57343 3.924 7.60069V16.3992C3.924 18.4266 5.57331 20.076 7.60057 20.0759H16.3992ZM6.85709 12.0001C6.85709 9.16418 9.16413 6.85709 11.9999 6.85709C14.8358 6.85709 17.1428 9.16418 17.1428 12.0001C17.1428 14.8358 14.8358 17.1428 11.9999 17.1428C9.16413 17.1428 6.85709 14.8358 6.85709 12.0001ZM8.62792 11.9999C8.62792 13.8592 10.1407 15.3718 11.9999 15.3718C13.8592 15.3718 15.372 13.8592 15.372 11.9999C15.372 10.1405 13.8593 8.62784 11.9999 8.62784C10.1406 8.62784 8.62792 10.1405 8.62792 11.9999Z'
                                            fill='white'
                                        />
                                    </mask>
                                    <g mask='url(#mask0_1201_15608)'/>
                                </svg>
                            </AnimatedLink>
                        </div>
                        <div className='col-auto'>
                            <AnimatedLink className='text-success link-success d-inline-block'
                                          customScale={1.3}
                                          to='https://www.tiktok.com/@produkacja'>
                                <img alt='Logo TikToka' style={{width: "32px", height: "32px"}}
                                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/kontakt/tiktok.png'/>
                            </AnimatedLink>
                        </div>
                        <div className='col-auto'>
                            <AnimatedLink className='text-success link-success d-inline-block'
                                          customScale={1.3}
                                          to='https://www.youtube.com/@produkacja'>
                                <img alt='Logo Youtube' style={{width: "36px", height: "36px"}}
                                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/kontakt/youtube.png'/>
                            </AnimatedLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-2'>
                <div className='mw-md-xs'>
                    <h3 className='font-heading mb-5 fs-10 text-white'>
                        Produkt
                    </h3>
                    <ul className='nav flex-column'>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/oferta/kurs-efektywnego-dzialania'
                            >
                                Kurs Efektywnego Działania
                            </AnimatedLink>
                        </li>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/oferta/chat'
                            >
                                Wirtualny korepetytor
                            </AnimatedLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-2'>
                <div className='mw-md-xs'>
                    <h3 className='font-heading mb-5 fs-10 text-white'>
                        Pozostałe
                    </h3>
                    <ul className='nav flex-column'>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/blog'
                            >
                                Blog
                            </AnimatedLink>
                        </li>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/chronotypy'
                            >
                                Test na chronotypy snu
                            </AnimatedLink>
                        </li>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/planer'
                            >
                                Planer
                            </AnimatedLink>
                        </li>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/polityka-prywatnosci'
                            >
                                Polityka prywatności
                            </AnimatedLink>
                        </li>
                        <li className='nav-item mb-4'>
                            <AnimatedLink
                                className='nav-link p-0 fs-11 link-secondary-light'
                                to='/regulamin'
                            >
                                Regulamin
                            </AnimatedLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
                <div className='mw-md-sm'>
                    <h3 className='font-heading mb-5 fs-10 text-white'>
                        Newsletter
                    </h3>
                    <NewsletterForm type={NewsletterType.Newsletter} cancelAnimation={true}/>
                </div>
            </div>
        </div>
        <div
            className='position-relative border-bottom border-light-dark'
        />
        <p className='mb-0 pt-10 pb-10 fs-12 fw-medium text-center text-secondary-light'>
            © 2025 Produkacja. Wszelkie prawa zastrzeżone.
        </p>
    </MainDesign>
}