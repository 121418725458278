import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import AdminKedOffer from "../../../components/admin/ked/AdminKedOffer";
import React from "react";
import {CreateIndividualKedOfferRequest} from "../../../model/user/purchase/IndividualKedOffer";
import {PurchaseService} from "../../../service/PurchaseService";
import {ApiError} from "../../../service/HttpService";

export default function AdminKedOfferPage() {
    return <>
        <Meta title='KED - Oferta'/>
        <MainDesign containerStyles='container pt-20'>
            <AdminKedOffer/>
        </MainDesign>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    const requestBody = {
        price: formData.get('price') as unknown as number,
        name: formData.get('name') as string,
        type: formData.get('type') as string,
        expiresAt: formData.get('expiresAt') as string,
        benefits: (formData.get('benefits') as string).split('###'),
        additionalComment: formData.get('additionalComment') as string
    } as CreateIndividualKedOfferRequest;

    try {
        const responseData = await PurchaseService.createIndividualKedOffer(requestBody);

        return {
            status: 200,
            body: responseData.url
        };
    } catch (error) {
        console.log(error);
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd."
        };
    }
}