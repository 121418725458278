import React, {KeyboardEvent, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Link, useSearchParams, useSubmit} from "react-router-dom";

import Heading from "../../../common/header/Heading";
import WebinarTestimonials from "./WebinarTestimonials";

import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import {WebinarService} from "../../../../service/WebinarService";
import {formatDate, formatDateWithHourWord} from "../../../../util/DateUtil";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import {KED_WEBINAR_EXPERIMENT_1} from "../../../../util/ExperimentUtil";
import WebinarPlayer from "../../../user/WebinarPlayer";
import Chat from "./Chat";
import VideoWithPlayButton from "../../../ui/video/VideoWithPlayButton";
import {DiagnosticService} from "../../../../service/DiagnosticService";


interface WebinarProps {
    webinarDetails: WebinarDetails;
}

// The total run-time of your webinar in seconds
export const WEBINAR_DURATION_IN_SECONDS = 90 * 60 + 60 * 4 + 7;

// 10 minutes in seconds
const TEN_MINUTES = 60 * 10;

// PITCH_TIME_IN_SECONDS
const PITCH_TIME_IN_SECONDS = 60 * 60 + 14 * 60 + 5;

// Fake messages array (identical to your original)
const FAKE_MESSAGES = [
    {
        message: "Cześć wszystkim! Za chwilkę zaczynamy.",
        sendAfter: "0.3s",
        userName: "Mikołaj (Organizator)",
        fromOrganizer: true,
    },
    {
        message: "Super!",
        sendAfter: "10s",
        userName: "Anna",
        fromOrganizer: false,
    },
    {
        message: "Nie mogę się doczekać! 😎",
        sendAfter: "11.2s",
        userName: "Marta",
        fromOrganizer: false,
    },
    {
        message: "Witam wszystkich",
        sendAfter: "11.3s",
        userName: "Marcin",
        fromOrganizer: false,
    },
    {
        message: "📝",
        sendAfter: "11.33s",
        userName: "monika",
        fromOrganizer: false,
    },
    {
        message: "dzień dobry",
        sendAfter: "11.35s",
        userName: "Martyna",
        fromOrganizer: false,
    },
    {
        message: "Cześć!",
        sendAfter: "11.5s",
        userName: "kuba",
        fromOrganizer: false,
    },
    {
        message: "Dzień dobry",
        sendAfter: "12.3s",
        userName: "Jan",
        fromOrganizer: false,
    },
    {
        message: "Dzień dobry Panie Mikołaju",
        sendAfter: "13.8s",
        userName: "patrycja k.",
        fromOrganizer: false,
    },
    {
        message: "Cześć",
        sendAfter: "14s",
        userName: "Monika",
        fromOrganizer: false,
    },
    {
        message: "Bardzo ciekawi mnie ten webinar. Jestem przekonana, że dużo się dzisiaj nauczę:)",
        sendAfter: "14.3s",
        userName: "Katarzyna",
        fromOrganizer: false,
    },
    {
        message: "Tak",
        sendAfter: "15.3s",
        userName: "Monika",
        fromOrganizer: false,
    },
    {
        message: "Słychać słychać",
        sendAfter: "15.7s",
        userName: "Małgorzata",
        fromOrganizer: false,
    },
    {
        message: "slychać i widać",
        sendAfter: "15.9s",
        userName: "Adam",
        fromOrganizer: false,
    },
    {
        message: "Słychać!",
        sendAfter: "16.4s",
        userName: "Jane",
        fromOrganizer: false,
    },
    {
        message: "Słychać dobrze",
        sendAfter: "16.9s",
        userName: "Małgorzata",
        fromOrganizer: false,
    },
    {
        message: "🧡🧡🧡",
        sendAfter: "17s",
        userName: "Marta",
        fromOrganizer: false,
    },
    {
        message: "tak",
        sendAfter: "17.5s",
        userName: "Małgorzata",
        fromOrganizer: false,
    },
    {
        message: "Wszystko działa",
        sendAfter: "18s",
        userName: "Maria",
        fromOrganizer: false,
    },
    {
        message: "Super 😃",
        sendAfter: "18.5s",
        userName: "Ilona",
        fromOrganizer: false,
    },
    {
        message: "Można się jeszcze z Panem umówić na indywidualne konsultacje?",
        sendAfter: "19s",
        userName: "Kasia",
        fromOrganizer: false,
    },
    {
        message: "Witam wszystkich",
        sendAfter: "19.3s",
        userName: "Matylda",
        fromOrganizer: false,
    },
    {
        message: "Dzień dobry",
        sendAfter: "20.3s",
        userName: "Maria",
        fromOrganizer: false,
    },
    {
        message: "Miło Pana znowu słyszeć 🙂",
        sendAfter: "21.3s",
        userName: "Agnieszka",
        fromOrganizer: false,
    },
    {
        message: "Wszyscy w trójkę pana sluchamy :)",
        sendAfter: "22s",
        userName: "Bogumiła",
        fromOrganizer: false,
    },
    {
        message: "czesc",
        sendAfter: "22.3s",
        userName: "Józef",
        fromOrganizer: false,
    },
    {
        message: "Nie mogę się doczekać...",
        sendAfter: "22.8s",
        userName: "Piotr",
        fromOrganizer: false,
    },
    {
        message: "Jestem w pracy ale nie mogę przegapić spotkania z Panem!",
        sendAfter: "23.3s",
        userName: "Agata",
        fromOrganizer: false,
    },
    {
        message: "Wszystko słychać i widać",
        sendAfter: "23.9s",
        userName: "Mateusz",
        fromOrganizer: false,
    },
    {
        message: "czy ten webinar jest nagrywany?",
        sendAfter: "24.3s",
        userName: "Marcin",
        fromOrganizer: false,
    },
    {
        message: "Będzie Pan udostępniał swoją prezentację?",
        sendAfter: "24.8s",
        userName: "Renata",
        fromOrganizer: false,
    },
    {
        message: "Słuchajcie i naprawdę dużo się nauczycie od Mikołaja. Córka bardzo zadowolona.",
        sendAfter: "25.1s",
        userName: "Dorota",
        fromOrganizer: false,
    },
    {
        message: "cześć wszystkim 😀",
        sendAfter: "25.5s",
        userName: "Andrzej",
        fromOrganizer: false,
    },
    {
        message: "Panie Mikołaju, dziękuję bardzo za pomoc! Po naszej współpracy, Ignacy dostaje same 5tki w szkole i samemu uczy się bardzo chętnie!",
        sendAfter: "26s",
        userName: "Alicja",
        fromOrganizer: false,
    },
    {
        message: "Przestawiliśmy Chat na tryb prywatny, aby uniknąć zamieszania. Tylko ja i Mikołaj będziemy widzieć Wasze wiadomości.",
        sendAfter: "26.5s",
        userName: "Marysia (Moderator)",
        fromOrganizer: true,
    },
];

// Helper to parse "5s" => 5000 ms
function parseSendAfter(sendAfter: string): number {
    const numericPart = parseFloat(sendAfter.replace("s", ""));
    return numericPart * 1000;
}

function formatTimeLeft(seconds: number) {
    if (seconds < 0) return "0s";
    const s = Math.floor(seconds % 60);
    const m = Math.floor((seconds / 60) % 60);
    const h = Math.floor(seconds / 3600);
    if (h > 0) return `${h}h ${m}m ${s}s`;
    if (m > 0) return `${m}m ${s}s`;
    return `${s}s`;
}

/** Framer Motion fade variants */
const fadeVariants = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {duration: 0.5},
    },
    exit: {
        opacity: 0,
        transition: {duration: 0.3},
    },
};

/** A small repeating animation for time-left text */
const timeBounceVariants = {
    animate: {
        scale: [1, 1.08, 1],
        transition: {
            repeat: Infinity,
            duration: 1.5,
            ease: "easeInOut",
        },
    },
};

/** Dots animation for "Webinar zaraz się zacznie..." */
const dotsContainer = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.25,
        },
    },
};

const dot = {
    hidden: {opacity: 0.2},
    visible: {
        opacity: [0.2, 1, 0.2],
        transition: {
            duration: 1,
            repeat: Infinity,
            repeatType: "loop" as const,
        },
    },
};

function AnimatedDots() {
    return (
        <motion.div
            variants={dotsContainer}
            initial="hidden"
            animate="visible"
            style={{display: "inline-flex", marginLeft: "8px"}}
        >
            <motion.span className="mx-1" variants={dot}>
                .
            </motion.span>
            <motion.span className="mx-1" variants={dot}>
                .
            </motion.span>
            <motion.span className="mx-1" variants={dot}>
                .
            </motion.span>
        </motion.div>
    );
}

export default function Webinar({webinarDetails}: WebinarProps) {
    // Basic scheduling
    const [currentTime, setCurrentTime] = useState(Date.now());
    const plannedTime = new Date(webinarDetails.plannedDate).getTime();
    const dateDiff = (currentTime - plannedTime) / 1000; // in seconds

    const hasNotStarted = dateDiff < 0;
    const hasEnded = dateDiff > WEBINAR_DURATION_IN_SECONDS;
    const isRunning = !hasNotStarted && !hasEnded;

    // For tracking watch time in seconds (updated by WebinarPlayer)
    const [watchTime, setWatchTime] = useState(webinarDetails.watchTime > 0 && webinarDetails.watchTime < WEBINAR_DURATION_IN_SECONDS ? webinarDetails.watchTime : 0);

    // Chat + discount logic (unchanged)
    const submit = useSubmit();
    const {isSubmitting} = useSubmitFormAction(
        "whatever",
        null,
        (body) => {
            window.open(body, "_blank");
        },
        false,
        undefined,
        undefined,
        true
    );

    const [searchParams] = useSearchParams();
    const variation = searchParams.get("variation");
    const source = searchParams.get("source") ?? webinarDetails.id;

    function createTransaction() {
        TraceService.addTraceWithExperiment(
            TraceType.InitTransaction,
            KED_WEBINAR_EXPERIMENT_1,
            variation,
            source
        );
        submit(null, {
            method: "POST",
        });
    }

    // Update currentTime every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // This is your discountDeadline logic:
    const discountDeadline =
        plannedTime + WEBINAR_DURATION_IN_SECONDS * 1000 + TEN_MINUTES * 1000;
    const discountSecondsLeft = Math.floor((discountDeadline - Date.now()) / 1000);
    const discountExpired = discountSecondsLeft <= 0;

    // Chat messages
    const [chatMessages, setChatMessages] = useState<
        {
            text: string;
            isUser: boolean;
            timestamp: Date;
            userName?: string;
            fromOrganizer?: boolean;
        }[]
    >([]);

    const [userInput, setUserInput] = useState("");
    const chatContainerRef = useRef<HTMLDivElement>(null);

    // Scroll chat down on new messages
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    // Load existing user messages
    useEffect(() => {
        if (!webinarDetails?.chatMessages) return;
        const loadedMessages = Object.entries(webinarDetails.chatMessages).map(
            ([isoDate, text]) => ({
                text,
                isUser: true,
                timestamp: new Date(isoDate),
                userName: webinarDetails?.userName || "Ty",
                fromOrganizer: false,
            })
        );

        setChatMessages((prev) => {
            const newEntries = loadedMessages.filter((msg) => {
                return !prev.some(
                    (p) =>
                        p.timestamp.getTime() === msg.timestamp.getTime() && p.text === msg.text
                );
            });
            return [...prev, ...newEntries].sort(
                (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
            );
        });
    }, [webinarDetails?.chatMessages, webinarDetails?.userName]);

    // Schedule the fake messages
    useEffect(() => {
        if (!webinarDetails?.plannedDate) return;
        FAKE_MESSAGES.forEach((fakeMsg) => {
            const messageTime = plannedTime + parseSendAfter(fakeMsg.sendAfter);
            const delay = messageTime - Date.now();
            if (delay <= 0) {
                // Already in the past => add immediately (skip duplicates)
                setChatMessages((prev) => {
                    const alreadyScheduled = prev.find(
                        (m) =>
                            m.timestamp.getTime() === messageTime && m.text === fakeMsg.message
                    );
                    if (alreadyScheduled) return prev;
                    return [
                        ...prev,
                        {
                            text: fakeMsg.message,
                            isUser: false,
                            timestamp: new Date(messageTime),
                            userName: fakeMsg.userName,
                            fromOrganizer: fakeMsg.fromOrganizer,
                        },
                    ].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
                });
            } else {
                // Future => schedule setTimeout
                setTimeout(() => {
                    setChatMessages((prev) => {
                        const alreadyScheduled = prev.find(
                            (m) =>
                                m.timestamp.getTime() === messageTime && m.text === fakeMsg.message
                        );
                        if (alreadyScheduled) return prev;
                        return [
                            ...prev,
                            {
                                text: fakeMsg.message,
                                isUser: false,
                                timestamp: new Date(messageTime),
                                userName: fakeMsg.userName,
                                fromOrganizer: fakeMsg.fromOrganizer,
                            },
                        ].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
                    });
                }, delay);
            }
        });
    }, [webinarDetails, plannedTime]);

    // Send message
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
        }
    };

    const handleSend = async () => {
        if (!userInput.trim()) {
            return;
        }

        try {
            WebinarService.addChatMessage({
                id: webinarDetails.id,
                message: userInput.trim(),
                // If watchTime is still 0, fallback to webinarDetails.watchTime
                watchTime: watchTime === 0 ? webinarDetails.watchTime ?? 0 : watchTime,
            });
        } catch (error) {
            DiagnosticService.addDiagnostic({error: error, fullEvent: {}, functionName: "webinar_add_chat_message"})
        }


        const now = new Date();
        setChatMessages((prev) => [
            ...prev,
            {
                text: userInput.trim(),
                isUser: true,
                timestamp: now,
                userName: webinarDetails?.userName || "Ty",
            },
        ]);
        setUserInput("");
    };

    // Time left to start
    const timeLeftToStart = plannedTime - currentTime;
    const secondsLeftToStart = Math.floor(timeLeftToStart / 1000);

    // Conditionally render discount block
    function renderDiscountBlock() {

        // If the webinar is still running but user hasn't watched >= PITCH_TIME
        if (isRunning && watchTime < PITCH_TIME_IN_SECONDS) {
            return null;
        }

        // Otherwise, show the discount CTA
        let countdownElement = null;
        if (!isRunning) {
            countdownElement = (
                <h2 className="fs-5 text-warning mb-6">
                    Promocja wygaśnie za:{" "}
                    <span className="fw-bold">{formatTimeLeft(discountSecondsLeft)}</span>
                </h2>
            );
        }

        return (
            <div className="col-12 text-center mt-10 mt-md-0 pt-md-8 mw-md-5xl mx-auto">
                {countdownElement}

                <h2 className="mb-5 text-success">
                    🔥 Zyskaj aż <span className="fw-bold">83% rabatu</span> na Kurs Efektywnego Działania! 🔥
                </h2>

                <p className="text-white fw-medium fs-5">
                    <span className="text-decoration-line-through text-danger fw-bold me-2">
                    5985 zł
                    </span>
                    <span className="text-success fw-bold">997 zł</span>
                </p>
                <p className="text-white fw-medium">
                    Jednorazowa płatność <span className="fw-bold text-success">997 zł</span> lub{" "}
                    <span className="fw-bold text-success">3 raty 0% (333 zł/mc)</span>{" "}
                    bez żadnych dodatkowych kosztów (0% prowizji)!
                </p>

                <div
                    className="d-flex flex-column flex-md-row align-items-center justify-content-center mt-4"
                    style={{gap: "20px"}}
                >
                    <AnimatedButton
                        className="btn btn-success btn-lg fw-bold purchase-button"
                        disabled={isSubmitting}
                        onClick={createTransaction}
                        type="button"
                    >
                        Kupuję już teraz
                    </AnimatedButton>
                    <h3 className="text-danger fs-9 mb-0 mt-2 mt-md-0 d-none d-md-block">
                        ⏳ Uwaga: Cena obowiązuje tylko do końca {!hasEnded ? "webinaru" : "dostępnego czasu"}!
                    </h3>
                </div>
                <p className={`mb-1 fs-13 fw-medium text-white text-start ${hasEnded ? 'mx-md-8' : 'mx-md-16'} ${hasEnded ? 'mx-lg-20' : 'mx-lg-32'} mt-1`}>
                    <span>Dokonując płatności akceptujesz </span>
                    <Link target="_blank" className="text-success link-success" to="/polityka-prywatnosci">
                        politykę prywatności
                    </Link>
                    <span>, </span>
                    <Link target="_blank" className="text-success link-success" to="/regulamin">
                        regulamin
                    </Link>
                    <span> oraz </span>
                    <Link target="_blank" className="text-success link-success" to="/regulamin-ked">
                        regulamin KED
                    </Link>
                    <span>. Wszystkie instrukcje dotyczące dostępu do kursu zostaną wysłane na adres email podany przy płatości.</span>
                </p>
            </div>
        );
    }

    return (
        <>
            <Heading
                title="Jak pomóc swojemu dziecku zostać NAJLEPSZYM uczniem?"
                badgeText={`Start: ${formatDate(webinarDetails.plannedDate)}`}
            />

            <div className="row mt-10">
                <AnimatePresence mode="wait">
                    {/* NOT STARTED */}
                    {hasNotStarted && (
                        <motion.div
                            key="notStarted"
                            className="text-center text-warning mt-2"
                            variants={fadeVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{width: "100%"}}
                        >
                            <p style={{minHeight: "50px"}} className="mb-0 fw-medium fs-8">
                                Webinar rozpocznie się{" "}
                                {formatDateWithHourWord(webinarDetails.plannedDate)}.
                            </p>

                            <h2 style={{minHeight: "50px"}} className="mb-0 mt-10 fs-6">
                                Pozostało jeszcze:
                            </h2>

                            <motion.div
                                variants={timeBounceVariants}
                                animate="animate"
                                className="fs-6 text-success fw-medium"
                                style={{marginTop: "5px"}}
                            >
                                <p>
                                    {formatTimeLeft(secondsLeftToStart)}
                                    <AnimatedDots/>
                                </p>
                            </motion.div>

                            <VideoWithPlayButton vimeoVideoId='1048244445' id={'thank-you'}/>

                            <div className="mw-md-2xl mx-auto text-center text-white mt-16">
                                <WebinarTestimonials/>
                            </div>
                        </motion.div>
                    )}

                    {/* ENDED */}
                    {hasEnded && (
                        <motion.div
                            key="ended"
                            className="text-center text-warning mt-10"
                            variants={fadeVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{width: "100%"}}
                        >
                            <h2 style={{minHeight: "50px"}}>Webinar został zakończony.</h2>
                            {renderDiscountBlock()}
                        </motion.div>
                    )}

                    {/* RUNNING */}
                    {isRunning && (
                        <motion.div
                            key="running"
                            className="d-flex flex-wrap"
                            variants={fadeVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{width: "100%"}}
                        >
                            {/* VIDEO (col-md-8) */}
                            <div
                                className="col-12 col-md-8 mb-4 mb-md-0 webinar-player-col"
                                style={{
                                    position: "relative",
                                    height: 0,
                                }}
                            >
                                <WebinarPlayer
                                    videoId="moqcio9axq"
                                    webinarDetails={webinarDetails}
                                    liveSimulation={true}
                                    start={dateDiff}
                                    onFirstMountOnce={() => {
                                        TraceService.addTraceWithExperiment(TraceType.PlayVideo, KED_WEBINAR_EXPERIMENT_1, variation, source)
                                    }}
                                    onWatchTimeUpdate={(secondsWatched) => {
                                        // Called every second by secondchange
                                        // Update both server and local watchTime
                                        if (secondsWatched % 5 === 0) {
                                            try {
                                                WebinarService.updateWebinarStatistics({
                                                    id: webinarDetails.id,
                                                    watchTime: secondsWatched,
                                                });
                                            } catch (error) {
                                                DiagnosticService.addDiagnostic({
                                                    functionName: `onWatchTimeUpdate: ${secondsWatched}`,
                                                    error: error, fullEvent: {}
                                                })
                                            }
                                        }
                                        setWatchTime((prev) =>
                                            secondsWatched > prev ? secondsWatched : prev
                                        );
                                    }}
                                    showProgressBar={false}
                                    autoPlay={true}
                                    forceContinuousPlay={true}
                                />
                            </div>

                            <div
                                className="d-block d-md-none col-12"
                                style={{
                                    position: "relative",
                                    minHeight: "500px",
                                    height: 0,
                                }}
                            >
                                <Chat
                                    chatContainerRef={chatContainerRef}
                                    chatMessages={chatMessages}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    handleKeyDown={handleKeyDown}
                                    handleSend={handleSend}
                                />
                            </div>

                            <div
                                className="d-none d-md-block col-md-4"
                                style={{
                                    position: "relative",
                                    paddingBottom: "37.5%",
                                    height: 0,
                                }}
                            >
                                <Chat
                                    chatContainerRef={chatContainerRef}
                                    chatMessages={chatMessages}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    handleKeyDown={handleKeyDown}
                                    handleSend={handleSend}
                                />
                            </div>

                            {/* Discount block, etc. */}
                            {renderDiscountBlock()}
                        </motion.div>
                    )}

                </AnimatePresence>
            </div>
        </>
    );
}