import Heading from "../../../../common/header/Heading";
import FaqList from "../../../../ui/faq/FaqList";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";

export default function ChatFAQ() {
    return <>
        <div className='mt-20'>
            <Heading title='Najczęściej zadawane pytania' badgeText='FAQ'
                     subtitle='Dowiedz się więcej i znajdź odpowiedzi na swoje pytania.'/>

            <FaqList items={[
                {
                    question: 'Czy mogę zobaczyć, jak to działa za darmo?',
                    answer: 'Oczywiście. Każdy użytkownik po stworzeniu konta otrzyma 20 tokenów do wykorzystania, dzięki którym odkryje możliwości AI.'
                },
                {
                    question: 'Czym są tokeny?',
                    answer: 'Token jest wirtualną walutą, którą wykorzystuje się przy każdym zapytaniu do AI. ' +
                        'W zależności od wybranego modelu, cena zapytania wahać się będzie od 1 do 5 tokenów. ' +
                        'Cena każdego wywołania sztucznej inteligencji będzie jasno podana.'
                },
                {
                    question: 'Co to są modele?',
                    answer: 'Modelem nazywamy odpowiednio skonfigurowaną sztuczną inteligencję, która ma za zadanie zachowywać się w określony sposób. '
                },
                {
                    question: 'Czym różni się chat Produkacji od chatu GPT?',
                    answer: 'Chat GPT jest zwykłym modelem, który jest nauczony, aby odpowiadać w sposób bardzo ogólny.\n' +
                        'Darmowa wersja Chatu GPT popełnia mnóstwo błędów, na które u nas nie ma miejsca.\n' +
                        'W naszym rozwiązaniu oferujemy różne modele, z których każdy został skonfigurowany w taki sposób, aby kompleksowo (i w miarę możliwości bezbłędnie) odpowiadać na pytania ucznia. '
                },
                {
                    question: 'Czy kupując tokeny zobowiązuję się do czegoś?',
                    answer: 'Oczywiście, że nie. Płacisz tylko za to, co wykorzystujesz. Masz miesiąc przerwy? Nic nie szkodzi, Twoje tokeny cały będą na Ciebie czekać.\n ' +
                        'Gdy skończą Ci się tokeny, wybierasz wygodną dla Ciebie metodę płatności i Twoje konto zostaje zasilone nową liczbą tokenów.'
                },
                {
                    question: 'Na ile wystarczą mi tokeny?',
                    answer: 'Wszystko zależy od tego, jak często będziesz korzystać z naszych narzędzi.\n ' +
                        'W przypadku sporadycznego używania, miesięcznie zużyjesz około 200 tokenów.\n' +
                        'W przypadku intensywnego używania, możesz zużyć nawet ponad 1000 tokenów.'
                }
            ]}/>

            <div className='mt-20 text-center'>
                <Heading title='Dalej masz pytania?'
                         subtitle='Skontaktuj się z nami, chętnie na nie odpowiemy.'/>
                <AnimatedLink className='btn btn-lg btn-success w-100 text-success-light shadow mw-md-md'
                              to='/kontakt'>Kontakt</AnimatedLink>
            </div>

        </div>
    </>
}