import React, {useEffect, useState} from "react";
import {NewsletterType} from "../../user/newsletter/NewsletterForm";
import {NewsletterEntity} from "../../../model/user/general/Newsletter";
import CustomSelect from "../../ui/form/CustomSelect";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useNavigate, useSearchParams, useSubmit} from "react-router-dom";
import ConfirmModal from "../../ui/ConfirmModal";

interface MailingListTableProps {
    mailingList: NewsletterEntity[]
}

export default function MailingListTable({mailingList}: MailingListTableProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const options = Object.keys(NewsletterType)

    const [selectedMailingList, setSelectedMailingList] = useState<NewsletterType>(searchParams.get('mailingList') as NewsletterType || NewsletterType.WebinarKedOptInV1);
    const [items, setItems] = useState<NewsletterEntity[]>(mailingList);

    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [entityToToggle, setEntityToToggle] = useState<NewsletterEntity | null>(null);

    const submit = useSubmit();

    async function toggleMailingList() {
        setConfirmModalOpen(false);
        submit({
            action: "TOGGLE_NEWSLETTER",
            email: entityToToggle!!.email,
            type: selectedMailingList
        }, {
            method: "POST"
        });
        setEntityToToggle(null);
    }

    useEffect(() => {
        setItems(mailingList);
    }, [mailingList])

    function onMailingListChange(mailingList: NewsletterType) {
        setSelectedMailingList(mailingList);
        navigate(`?mailingList=${mailingList}`, {replace: true});
        setSearchParams({mailingList: mailingList});
    }

    return <>
        <div className=' mw-md-md mx-auto my-10'>
            <p className='text-white fw-bold mb-1'>Wybierz listę mailingową</p>
            <CustomSelect name='mailingList' value={selectedMailingList}
                          onChange={(event) => {
                              onMailingListChange(event.target.value as NewsletterType)
                          }}
                          customStyles={'select-md'}>
                {
                    options.map(model => (
                        <option value={model} key={model}>{model}</option>
                    ))
                }
            </CustomSelect>
        </div>

        <div style={{overflowX: "auto"}} className='text-center'>
            <table className="table bg-light-dark text-white px-1 text-center mx-auto fs-11 rounded"
                   style={{boxSizing: "unset", overflowX: "visible"}}>
                <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Imię</th>
                    <th scope="col">Data dołączenia</th>
                    <th scope="col">Źródło</th>
                    <th scope="col">Wysłano FU (suma)</th>
                    <th scope="col">Ostatnio wysłano FU (data)</th>
                    <th scope="col" style={{minWidth: '180px'}}>Otwarte FU</th>
                    <th scope="col" style={{minWidth: '180px'}}>Kliknięte FU</th>
                    <th scope="col">Akcja</th>
                </tr>
                </thead>
                <tbody>
                {
                    items.map((newsletterEntity: NewsletterEntity) => (
                        <tr key={newsletterEntity.email + newsletterEntity.pk}>
                            <th scope="row"
                                className={newsletterEntity.active ? '' : 'text-decoration-line-through'}>{newsletterEntity.email}</th>
                            <td className={newsletterEntity.active ? '' : 'text-decoration-line-through'}>{newsletterEntity.name ?? 'unknown'}</td>
                            <td>{newsletterEntity.joinedAt}</td>
                            <td>{newsletterEntity.source}</td>
                            <td>{newsletterEntity.lastSentFollowUpEmail}</td>
                            <td>{newsletterEntity.lastSentFollowUpEmailAt}</td>
                            <td>
                                <div style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineHeight: '1.5'}}>
                                    {(() => {
                                        try {
                                            // Parse the JSON string into an array of objects
                                            const followUps = JSON.parse(newsletterEntity.openedFollowUps);

                                            // Check if the parsed value is an array and process it
                                            if (Array.isArray(followUps) && followUps.length > 0) {
                                                return followUps.map((item) => {
                                                    // Parse each object from the array
                                                    const parsed = typeof item === 'string' ? JSON.parse(item) : item;
                                                    return (
                                                        <span key={parsed.num} style={{display: 'block'}}>
                {String(`${parsed.num}: ${new Date(parsed.openedAt).toLocaleString()}`)}
              </span>
                                                    );
                                                });
                                            }
                                        } catch (error) {
                                            // Handle errors (invalid JSON or other issues)
                                            return '-';
                                        }

                                        // If no valid data exists, return '-'
                                        return '-';
                                    })()}
                                </div>
                            </td>
                            <td>
                                <div style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineHeight: '1.5'}}>
                                    {(() => {
                                        try {
                                            // Parse the JSON string into an array of objects
                                            const followUps = JSON.parse(newsletterEntity.clickedFollowUps);

                                            // Check if the parsed value is an array and process it
                                            if (Array.isArray(followUps) && followUps.length > 0) {
                                                return followUps.map((item) => {
                                                    // Parse each object from the array
                                                    const parsed = typeof item === 'string' ? JSON.parse(item) : item;
                                                    return (
                                                        <span key={parsed.num} style={{display: 'block'}}>
                {String(`${parsed.num}: ${new Date(parsed.openedAt).toLocaleString()}`)}
              </span>
                                                    );
                                                });
                                            }
                                        } catch (error) {
                                            // Handle errors (invalid JSON or other issues)
                                            return '-';
                                        }

                                        // If no valid data exists, return '-'
                                        return '-';
                                    })()}
                                </div>
                            </td>
                            <td>
                                <AnimatedButton
                                    className={`btn btn-sm ${newsletterEntity.active ? 'btn-warning' : 'btn-success'} text-success-light shadow`}
                                    onClick={() => {
                                        setEntityToToggle(newsletterEntity);
                                        setConfirmModalOpen(true)
                                    }}>{newsletterEntity.active ? 'Dezaktywuj' : 'Aktywuj'}</AnimatedButton>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen}
                      title={`Czy na pewno chcesz ${entityToToggle?.active ? 'dezaktywować' : 'aktywować'} newsletter dla ${entityToToggle?.email}?`}
                      subtitleWarn={`Operujesz na liście: ${selectedMailingList}`}
                      buttonType='button'
                      subtitle={entityToToggle?.active ? `Jeżeli ta lista mailingowa otrzymuje automatyczne maile, zostaną one anulowane dla ${entityToToggle.email}.` : 'Jeżeli ta lista mailingowa otrzymuje automatyczne maile, nie zostaną one wznowione.'}
                      confirmButtonAction={toggleMailingList}/>
    </>
}