import React from "react";

export const KED_OPT_IN_ALL_EXPERIMENT_ID_1 = "ked-opt-in-all-10-07"

export const KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_1 = "ked-opt-in-matura-parent-22-07"
export const KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_2 = "ked-opt-in-matura-parent-03-08"
export const KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_3 = "ked-opt-in-matura-parent-07-08"
export const KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1 = "ked-sales-call-matura-parent-22-07"

export const KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1 = "ked-direct-matura-parent-22-08"

export const MW_EXPERIMENT_ID_1 = "mw-10-09"
export const MW_STUDENT_EXPERIMENT_ID_1 = "mw-student-10-09"

export const FB_ADS_DIRECT_EXPERIMENT_ID_1 = "fb-ads-direct-04-09"
export const FB_ADS_DIRECT_EXPERIMENT_ID_2 = "fb-ads-direct-18-09"

export const MW_FULL_FUNNEL_EXPERIMENT_1 = "mw-05-10"

export const MW_FULL_FUNNEL_EXPERIMENT_2 = "mw-03-11"


export const KED_WEBINAR_EXPERIMENT_1 = "ked-webinar-1"

export interface OptInProps {
    handleButtonClick: (buttonNum: number, variation: ("A" | "B")) => void
    confirmModalOpen: boolean
    setConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    loading: boolean
    isInputValid: boolean
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
}

export function getExperimentVariation(experimentId: string) {
    let storedVariation = localStorage.getItem(`ab-variation-${experimentId}`);
    return storedVariation ?? "unknown";
}

export async function getSourceAndVariationFromRequest(request: Request, experimentId: string): Promise<{
    source: string,
    variation: string
}> {
    const queryString = request.url.split('?').length > 1 ? request.url.split('?')[1] : '';
    const searchParams = new URLSearchParams(queryString);
    // TODO: Temporary change - should be removed in the future, after enabling A/B testing
    const source = searchParams.get('source') ?? 'unknown'
    const variation = searchParams.get(`ab-variation-${experimentId}`) ?? 'A'

    return {source, variation}
}