import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {MW_FULL_FUNNEL_EXPERIMENT_2} from "../../../util/ExperimentUtil";
import React from "react";
import usePageTracking from "../../../hooks/usePageTracking";
import useOptInForm from "../../../hooks/useOptInForm";
import ProdukacjaCopyrightNoteWithPhoneNumber from "../../../components/funnel/ProdukacjaCopyrightNoteWithPhoneNumber";
import MWOptIn from "../../../components/funnel/optin/MWOptIn";

export default function FunnelMWParentOptInPage() {
    usePageTracking();

    const {
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        email,
        setEmail,
        name,
        setName,
        isInputValid,
        handleButtonClick,
    } = useOptInForm(MW_FULL_FUNNEL_EXPERIMENT_2);

    return (
        <>
            <Meta
                title="Jak zostać najlepszym uczniem?"
                description="Bezpłatne szkolenie pod tytułem 'Jak zostać NAJLEPSZYM uczniem' dla rodziców licealistów."
            />
            <MainDesign containerStyles="container pt-10 pt-md-20 pb-10" minHeight='100vh'>
                <MWOptIn handleButtonClick={handleButtonClick}
                         email={email} name={name} setName={setName}
                         setEmail={setEmail}
                         confirmModalOpen={confirmModalOpen}
                         setConfirmModalOpen={setConfirmModalOpen}
                         isInputValid={isInputValid}
                         loading={loading}/>
                <ProdukacjaCopyrightNoteWithPhoneNumber/>
            </MainDesign>
        </>
    );
}
