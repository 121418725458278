import Heading from "../../common/header/Heading";

export default function RedirectAfterRegistration() {
    return <>
        <div className='mw-md-3xl mx-auto'>
            <Heading badgeText='Rejestracja udana!'
                     customTitleStyles='font-heading mb-4 fs-5 text-warning'
                     title='Potwierdź swój adres email!'
                     subtitle='Kliknij w link otrzymany na maila, aby dokończyć aktywację konta. Jeżeli nie widzisz wiadomości, sprawdź folder spam.'/>
            <img src='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/after-register.png'
                 alt='Dziękujemy za rejestrację' className='img-fluid'/>
            <p className='text-center fs-11 fw-medium text-secondary-light mt-3'>
                Możesz zamknąć tę stronę.</p>
        </div>
    </>
}